import Clickable from "./clickable";

const Footer = ({onClick}) => {
  const romanize = (num) => {
    if (!+num)
        return false;
    var digits = String(+num).split(""),
        key    = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                  "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                  "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman  = "",
        i      = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }

  return (
    <div className="width80">
      <Clickable
        title="back to top"
        onClick={onClick}
        />
      <div className="right">
        (c) littlebitspace {romanize(new Date().getFullYear())}
      </div>
    </div>
  );
}

export default Footer;
