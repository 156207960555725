import { Fragment } from "react";

const Spacer = ({size=1, line}) => {
  let arr = [];
  for (let i = 0; i < size; i++) arr.push(i);
  return (
    <Fragment>
      {arr.map((el) =>
        <div className="width80" key={el}>{
          line ? "--------------------------------------------------------------------------------" : <br />
        }</div>
      )}
    </Fragment>
  );
};

export default Spacer;
