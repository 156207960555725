import { NavLink } from "react-router-dom";
import Ascii from "../components/ascii";
import Spacer from "../components/spacer";

const E404 = () => {
  return (
    <div className="center">
      <NavLink to="/"><Ascii art="e404" clickable/></NavLink>
      <Spacer />
      <div>Page not found. Probably not existing. Go somewhere else!</div>
    </div>
  );
}

export default E404;
