import { Fragment } from "react";
import Spacer from "../components/spacer";
import MyLink from "../components/myLink";

const Resources = () => {
  return (
    <Fragment>
      <div className="center">Resources</div>
      <Spacer />
      <div className="width80">
        Resources and instructions can be found here. What's best, everything's free. If
        you like it, please spread the word!
        <Spacer />
        Is something lacking? Pop me a message and I'll see to it.
      </div>
      <Spacer size={2}/>
      <div className="center">Fonts</div>
      <Spacer />
      <div className="width80">
Cultist Script is a calligraphic typeface designed for Commodore 64. The font is inspired by the occult and thus has a bit darker and grittier look. In addition, the typeface is accompanied by curves, textures and lots of dithering characters which makes it ideal for PETSCII art.
        <Spacer />
        <a className="clickable" href="/resources/cultist_script.64c" download>cultist_script.64c</a>
        <br />
        <a className="clickable" href="/resources/cultist_script.png" download>cultist_script.png</a>
      </div>
      <Spacer />
      <div className="width80">
Memento is probably known for the constant use of double strokes. The font plays with thin pencil-sketching-like lines while staying loyal to the traditional C64 font in regards of character types. That being said, most PETSCII art pieces can be somewhat directly converted into Memento sketches if, however, you didn't use any of the inverted characters.
        <Spacer />
        <a className="clickable" href="/resources/memento.64c" download>memento.64c</a>
        <br />
        <a className="clickable" href="/resources/memento.png" download>memento.png</a>
      </div>
      <Spacer />
      <div className="width80">
Castle Kit focuses on graphical 8x8 castle tiles. It also features alphanumerics heavily inspired by the infamous font <MyLink title="Fraktur" url="https://en.wikipedia.org/wiki/Fraktur" />. This font or tile set is designed modularity in mind and the castle tiles can also be used to create little houses as well. Using this font is like a relaxing castle building game.
        <Spacer />
        <a className="clickable" href="/resources/castle_kit.64c" download>castle_kit.64c</a>
        <br />
        <a className="clickable" href="/resources/castle_kit.png" download>castle_kit.png</a>
      </div>
      <Spacer size={2} />
      <div className="center">Instructions</div>
      <Spacer />
      <div className="width80">
        Using a custom font in PETSCII is lots of fun. Nonetheless, using an alternative
        typefaces in textmode arts is exceedingly controversial. That is why I encourage
        you to try it! Like I've said before, all the choices makes an art piece unique.
        Choose to explore. Here's how:
        <Spacer />
        <ol>
          <li>Download <a className="clickable" href="/resources/cultist_script.64c" download>cultist_script.64c</a> or <a className="clickable" href="/resources/memento.64c" download>memento.64c</a></li>
          <li>Download, install and launch <MyLink title="Petmate" url="https://nurpax.github.io/petmate/"/></li>
          <li>{`Go to "File > Fonts..."`}</li>
          <li>Click "New Font from .64c", select the font and click "OK"</li>
          <li>Switch the charset with the dropdown at the far right-hand side of the window</li>
          <li>Start drawing spirals, pentagrams or whatever</li>
        </ol>
      </div>
      <Spacer size={2} />
      <div className="center">Examples</div>
      <Spacer />
      <div className="width80">
        Ever wondered how to display PETSCII as text on a website? Well, it's your lucky
        day because I've made a somewhat readable rendition out of the messy script I am
        using myself. No coding skills needed necessarily, copying and pasting will take
        you far. The file itself contains more information. Go download it!
        <Spacer />
        <a className="clickable" href="/resources/lbs-petscii_example-1.0.html" download>lbs-petscii_example-1.0.html</a>
        <Spacer />
      </div>
    </Fragment>
  );
}

export default Resources;
