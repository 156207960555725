import Clickable from "./clickable";

const Settings = ({onChangeFontSize, onToggleEffects, effectsOn, fontSize}) => {
  const romanTime = () => {
    const date = new Date();
    var y = date.getFullYear();
    var m = date.getMonth();
    var d = date.getDate();

    // calculate reference dates
    d -= (y % 4 == 0) * (m == 2) * (d > 24)
    var i = 13 + 2 * [3, 5, 7, 10].find(e => e = m)
    var n = i - 8
    var k = 29 + [0, 3, 0, 3, 2, 3, 2, 3, 3, 2, 3, 2, 3][m]

    // a: num of days before next reference date
    // R: stringified r
    var a = 0;
    var R = 'KAL';
    if (d > 1) { a = n - d; R = 'NON'}
    if (d > n) { a = i - d; R = 'ID'}
    if (d > i) { a = k - d; R = 'KAL'}
    a += 1

    // A: stringified a
    var A = '';
    if (a == 2) A = 'PR ';
    if (a >= 3) A = 'AD ' + 'X'.repeat(Math.floor(a/10)) + ['','I','II','III','IV','V','VI','VII','VIII','IX'][a % 10];

    // M: stringified m
    if (d > i) m = m % 12 + 1;
    var M = ['IAN', 'FEB', 'MAR', 'APR', 'MAI', 'IVN', 'IVL', 'AVG', 'SEP', 'OCT', 'NOV', 'DEC'][m]
    
    return(`${A} ${R} ${M}`);
  }

  return (
    <div className="width80">
      {/* <div className="left">
        {romanTime(new Date())}
      </div> */}
      <div className="left">
        <Clickable
          title={`[${effectsOn ? "X" : " "}]`}
          onClick={() => onToggleEffects()}
        />
        &nbsp;Effects
      </div>
      <div className="right">
        <Clickable
          disabled={fontSize <= 8}
          title="[-]"
          onClick={() => onChangeFontSize("dec")}
        />
        &nbsp;Font size&nbsp;
        <Clickable
          title="[+]"
          onClick={() => onChangeFontSize("inc")}
          />
      </div>
    </div>
  );
};


export default Settings;
