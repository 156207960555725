import { Fragment, useEffect, useRef, useState } from "react";

import seedrandom from "seedrandom";

import flower1 from "../petscii/flower/flower1.json";
import flowerCentre from "../petscii/flower/flower-centre.json";
import flowerCentrePetals from "../petscii/flower/flower-centre_petals.json";
import flowerCentreCorners from "../petscii/flower/flower-centre_corners.json";
import flowerPetals1 from "../petscii/flower/flower-petals1.json";
import flowerPetals2 from "../petscii/flower/flower-petals2.json";
import flowerPetals3 from "../petscii/flower/flower-petals3.json";
import flowerStems from "../petscii/flower/flower-stems.json";

import Spacer from "./spacer";

const petsciiCodes = [
  { code: 32, character: "\ue1a0" },
  { code: 1, character: "A" },
  { code: 2, character: "B" },
  { code: 3, character: "C" },
  { code: 4, character: "D" },
  { code: 5, character: "E" },
  { code: 6, character: "F" },
  { code: 7, character: "G" },
  { code: 8, character: "H" },
  { code: 9, character: "I" },
  { code: 10, character: "J" },
  { code: 11, character: "K" },
  { code: 12, character: "L" },
  { code: 13, character: "M" },
  { code: 14, character: "N" },
  { code: 15, character: "O" },
  { code: 16, character: "P" },
  { code: 17, character: "Q" },
  { code: 18, character: "R" },
  { code: 19, character: "S" },
  { code: 20, character: "T" },
  { code: 21, character: "U" },
  { code: 22, character: "V" },
  { code: 23, character: "W" },
  { code: 24, character: "X" },
  { code: 25, character: "Y" },
  { code: 26, character: "Z" },
  { code: 46, character: "." },
  { code: 44, character: "," },
  { code: 59, character: ";" },
  { code: 33, character: "!" },
  { code: 63, character: "?" },
  { code: 48, character: "0" },
  { code: 49, character: "1" },
  { code: 50, character: "2" },
  { code: 51, character: "3" },
  { code: 52, character: "4" },
  { code: 53, character: "5" },
  { code: 54, character: "6" },
  { code: 55, character: "7" },
  { code: 56, character: "8" },
  { code: 57, character: "9" },
  { code: 34, character: '"' },
  { code: 35, character: "#" },
  { code: 36, character: "$" },
  { code: 37, character: "%" },
  { code: 38, character: "&" },
  { code: 39, character: "'" },
  { code: 112, character: "┌" },
  { code: 110, character: "┐" },
  { code: 108, character: "▗" },
  { code: 123, character: "▖" },
  { code: 85, character: "╭" },
  { code: 73, character: "╮" },
  { code: 79, character: "\ue0cf" },
  { code: 80, character: "\ue0d0" },
  { code: 113, character: "┴" },
  { code: 114, character: "┬" },
  { code: 40, character: "(" },
  { code: 41, character: ")" },
  { code: 60, character: "<" },
  { code: 62, character: ">" },
  { code: 78, character: "╱" },
  { code: 77, character: "╲" },
  { code: 109, character: "└" },
  { code: 125, character: "┘" },
  { code: 124, character: "▝" },
  { code: 126, character: "▘" },
  { code: 74, character: "╰" },
  { code: 75, character: "╯" },
  { code: 76, character: "\ue06c" },
  { code: 122, character: "\ue0ba" },
  { code: 107, character: "├" },
  { code: 115, character: "┤" },
  { code: 27, character: "[" },
  { code: 29, character: "]" },
  { code: 31, character: "←" },
  { code: 30, character: "↑" },
  { code: 95, character: "◥" },
  { code: 105, character: "◤" },
  { code: 100, character: "▁" },
  { code: 111, character: "▂" },
  { code: 121, character: "▃" },
  { code: 98, character: "▄" },
  { code: 120, character: "\ue1b8" },
  { code: 119, character: "\ue1f7" },
  { code: 99, character: "▔" },
  { code: 116, character: "▎" },
  { code: 101, character: "▎" },
  { code: 117, character: "▍" },
  { code: 97, character: "▌" },
  { code: 118, character: "\ue0f6" },
  { code: 103, character: "\ue1ea" },
  { code: 106, character: "\ue1ea" },
  { code: 91, character: "┼" },
  { code: 43, character: "+" },
  { code: 82, character: "\ue072" },
  { code: 70, character: "\ue0c6" },
  { code: 64, character: "─" },
  { code: 45, character: "-" },
  { code: 67, character: "─" },
  { code: 68, character: "\ue0c4" },
  { code: 69, character: "\ue0c5" },
  { code: 84, character: "\ue074" },
  { code: 71, character: "\ue067" },
  { code: 66, character: "|" },
  { code: 93, character: "|" },
  { code: 72, character: "\ue068", },
  { code: 89, character: "\ue0d9", },
  { code: 47, character: "/" },
  { code: 86, character: "╳" },
  { code: 42, character: "*" },
  { code: 61, character: "=" },
  { code: 58, character: ":" },
  { code: 28, character: "£" },
  { code: 0, character: "@" },
  { code: 127, character: "▚" },
  { code: 104, character: "\ue0a8" },
  { code: 92, character: "\ue17c" },
  { code: 102, character: "▒" },
  { code: 81, character: "•" },
  { code: 87, character: "○" },
  { code: 65, character: "♠" },
  { code: 83, character: "♥" },
  { code: 88, character: "♣" },
  { code: 90, character: "♦" },
  { code: 94, character: "π" },
  { code: 96, character: "\0" },
  { code: 160, character: "\ue220" },
  { code: 129, character: "\ue241" },
  { code: 130, character: "\ue242" },
  { code: 131, character: "\ue243" },
  { code: 132, character: "\ue244" },
  { code: 133, character: "\ue245" },
  { code: 134, character: "\ue246" },
  { code: 135, character: "\ue247" },
  { code: 136, character: "\ue248" },
  { code: 137, character: "\ue249" },
  { code: 138, character: "\ue24a" },
  { code: 139, character: "\ue24b" },
  { code: 140, character: "\ue24c" },
  { code: 141, character: "\ue24d" },
  { code: 142, character: "\ue24e" },
  { code: 143, character: "\ue24f" },
  { code: 144, character: "\ue250" },
  { code: 145, character: "\ue251" },
  { code: 146, character: "\ue252" },
  { code: 147, character: "\ue253" },
  { code: 148, character: "\ue254" },
  { code: 149, character: "\ue255" },
  { code: 150, character: "\ue256" },
  { code: 151, character: "\ue257" },
  { code: 152, character: "\ue258" },
  { code: 153, character: "\ue259" },
  { code: 154, character: "\ue25a" },
  { code: 174, character: "\ue22e" },
  { code: 172, character: "\ue22c" },
  { code: 187, character: "\ue23b" },
  { code: 161, character: "\ue221" },
  { code: 191, character: "\ue23f" },
  { code: 176, character: "\ue230" },
  { code: 177, character: "\ue231" },
  { code: 178, character: "\ue232" },
  { code: 179, character: "\ue233" },
  { code: 180, character: "\ue234" },
  { code: 181, character: "\ue235" },
  { code: 182, character: "\ue236" },
  { code: 183, character: "\ue237" },
  { code: 184, character: "\ue238" },
  { code: 185, character: "\ue239" },
  { code: 162, character: '\ue222' },
  { code: 163, character: "\ue223" },
  { code: 164, character: "\ue224" },
  { code: 165, character: "\ue225" },
  { code: 166, character: "\ue226" },
  { code: 167, character: "\ue227" },
  { code: 240, character: "\ue2b0" },
  { code: 238, character: "\ue2ae" },
  { code: 236, character: "\ue2ac" },
  { code: 251, character: "\ue2bb" },
  { code: 213, character: "\ue275" },
  { code: 201, character: "\ue269" },
  { code: 207, character: "\ue26f" },
  { code: 208, character: "\ue270" },
  { code: 241, character: "\ue2b1" },
  { code: 242, character: "\ue2b2" },
  { code: 168, character: "\ue228" },
  { code: 169, character: "\ue229" },
  { code: 188, character: "\ue23c" },
  { code: 190, character: "\ue23e" },
  { code: 206, character: "\ue2ce" },
  { code: 205, character: "\ue2cd" },
  { code: 237, character: "\ue2ad" },
  { code: 253, character: "\ue2bd" },
  { code: 252, character: "\ue2bc" },
  { code: 254, character: "\ue2be" },
  { code: 202, character: "\ue26a" },
  { code: 203, character: "\ue26b" },
  { code: 204, character: "\ue2cc" },
  { code: 250, character: "\ue2fa" },
  { code: 235, character: "\ue2ab" },
  { code: 243, character: "\ue2b3" },
  { code: 155, character: "\ue25b" },
  { code: 157, character: "\ue25d" },
  { code: 159, character: "\ue25f" },
  { code: 158, character: "\ue25e" },
  { code: 223, character: "\ue27f" },
  { code: 233, character: "\ue2a9" },
  { code: 228, character: "\ue2e4" },
  { code: 239, character: "\ue2ef" },
  { code: 249, character: "\ue2f9" },
  { code: 226, character: "\ue2e2" },
  { code: 248, character: "▅", },
  { code: 247, character: "▆", },
  { code: 227, character: "\ue2e3" },
  { code: 244, character: "\ue2e5" },
  { code: 229, character: "\ue2e5" },
  { code: 245, character: "\ue2b5" },
  { code: 225, character: "\ue2e1" },
  { code: 246, character: "▋", },
  { code: 231, character: "▊", },
  { code: 234, character: "▊", },
  { code: 219, character: "\ue2db" },
  { code: 171, character: "\ue22b" },
  { code: 210, character: "\ue272" },
  { code: 198, character: "\ue2c6" },
  { code: 192, character: "\ue263" },
  { code: 173, character: "\ue32d" },
  { code: 195, character: "\ue263" },
  { code: 196, character: "\ue2c4" },
  { code: 197, character: "\ue2c5" },
  { code: 212, character: "\ue274" },
  { code: 199, character: "\ue2c7" },
  { code: 194, character: "\ue2dd" },
  { code: 221, character: "\ue2dd" },
  { code: 200, character: "\ue2c8" },
  { code: 217, character: "\ue2d9" },
  { code: 175, character: "\ue22f" },
  { code: 214, character: "\ue276" },
  { code: 170, character: "\ue22a" },
  { code: 189, character: "\ue23d" },
  { code: 186, character: "\ue23b" },
  { code: 156, character: "\ue25c" },
  { code: 128, character: "\ue240" },
  { code: 255, character: "\ue2bf" },
  { code: 232, character: "\ue2a8" },
  { code: 220, character: "\ue27c" },
  { code: 230, character: "\ue2e6" },
  { code: 209, character: "\ue271" },
  { code: 215, character: "\ue277" },
  { code: 193, character: "\ue261" },
  { code: 211, character: "\ue273" },
  { code: 216, character: "\ue278" },
  { code: 218, character: "\ue27a" },
  { code: 222, character: "\ue27e" },
  { code: 224, character: "\ue220" },
];

const colourSchemes = [
  ["yellow", "brown", "red", "redDark", "green", "greenDark"],
  ["yellow", "brown", "red", "redDark", "cyan", "greenDark"],
  ["red", "redDark", "yellow", "brown", "green", "greenDark"],
  ["red", "redDark", "yellow", "brown", "cyan", "greenDark"],
  ["white", "cyan", "blue", "blueDark", "green", "greenDark"],
  ["white", "cyan", "blue", "blueDark", "cyan", "greenDark"],
  ["yellow", "brown", "cyan", "magenta", "green", "greenDark"],
  ["yellow", "brown", "cyan", "magenta", "cyan", "greenDark"],
  ["white", "yellow", "red", "brown", "green", "greenDark"],
  ["white", "yellow", "red", "brown", "cyan", "greenDark"],
  ["blue", "blueDark", "cyan", "yellow", "green", "greenDark"],
  ["blue", "blueDark", "cyan", "yellow", "cyan", "greenDark"],
];

const Flower = ({caption, id}) => {
  const [chars, setChars] = useState([]);
  const [colourSchemeId, setColourSchemeId] = useState(0);
  const [centreId, setCentreId] = useState(0);
  const [centreCornerId, setCentreCornerId] = useState(0);
  const [centrePetalId, setCentrePetalId] = useState(0);
  const [petal1Id, setPetal1Id] = useState(0);
  const [petal2Id, setPetal2Id] = useState(0);
  const [petal3Id, setPetal3Id] = useState(0);
  const [stemId, setStemId] = useState(0);
  
  useEffect(() => {
    var width =  flower1.framebufs[0].width;
    var characters = flower1.framebufs[0].screencodes;
    var colours = flower1.framebufs[0].colors;
    
    var temp1 = [];
    var temp2 = [];

    // format the image to rows and cols
    for (let i = 0; i < characters.length; i++) {
      var j = i;
        
      var ch = {
        character: petsciiCodes.find((el) => el.code == characters[j]).character,
        colour: colours[j],
      };

      temp1.push(ch);
      if ((i + 1) % width == 0) {
        temp2.push(temp1);
        temp1 = [];
      }
    }

    var temp3 = [];
    var temp4 = [];

    // change elements
    for (let r = 0; r < temp2.length; r++) {
      for (let c = 0; c < temp2[r].length; c++) {
        var ch = temp2[r][c];
        if (true == false) console.log("KISSA");

        // PETAL3
        else if (r == 0 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[0]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[0]
        }
        else if (r == 0 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[1]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[1]
        }
        else if (r == 0 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[2]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[2]
        }
        else if (r == 0 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[3]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[3]
        }
        else if (r == 0 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[4]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[4]
        }
        else if (r == 0 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[5]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[5]
        }
        else if (r == 0 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[6]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[6]
        }
        else if (r == 0 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[7]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[7]
        }
        else if (r == 0 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[8]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[8]
        }
        else if (r == 0 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[9]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[9]
        }
        else if (r == 0 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[10]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[10]
        }
        else if (r == 0 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[11]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[11]
        }
        

        // PETAL3
        else if (r == 1 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[12]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[12]
        }
        else if (r == 1 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[13]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[13]
        }
        else if (r == 1 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[14]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[14]
        }
        else if (r == 1 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[15]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[15]
        }
        else if (r == 1 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[16]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[16]
        }
        else if (r == 1 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[17]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[17]
        }
        else if (r == 1 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[18]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[18]
        }
        else if (r == 1 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[19]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[19]
        }
        else if (r == 1 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[20]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[20]
        }
        else if (r == 1 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[21]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[21]
        }
        else if (r == 1 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[22]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[22]
        }
        else if (r == 1 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[23]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[23]
        }

        // PETAL3
        else if (r == 2 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[24]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[24]
        }
        else if (r == 2 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[25]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[25]
        }
        else if (r == 2 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[26]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[26]
        }
        else if (r == 2 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[27]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[27]
        }
        // PETAL2
        else if (r == 2 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[2]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[2]
        }
        else if (r == 2 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[3]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[3]
        }
        else if (r == 2 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[4]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[4]
        }
        else if (r == 2 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[5]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[5]
        }
        // PETAL3
        else if (r == 2 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[32]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[32]
        }
        else if (r == 2 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[33]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[33]
        }
        else if (r == 2 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[34]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[34]
        }
        else if (r == 2 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[35]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[35]
        }
        
        // PETAL3
        else if (r == 3 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[36]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[36]
        }
        else if (r == 3 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[37]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[37]
        }
        else if (r == 3 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[38]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[38]
        }
        // PETAL1
        else if (r == 3 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[0]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[0]
        }
        else if (r == 3 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[1]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[1]
        }
        else if (r == 3 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[2]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[2]
        }
        else if (r == 3 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[3]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[3]
        }
        else if (r == 3 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[4]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[4]
        }
        else if (r == 3 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[5]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[5]
        }
        // PETAL3
        else if (r == 3 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[45]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[45]
        }
        else if (r == 3 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[46]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[46]
        }
        else if (r == 3 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[47]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[47]
        }

        // PETAL3
        else if (r == 4 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[48]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[48]
        }
        else if (r == 4 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[49]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[49]
        }
        // PETAL2
        else if (r == 4 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[16]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[16]
        }
        // PETAL1
        else if (r == 4 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[6]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[6]
        }
        // CORNER 1
        else if (r == 4 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentreCorners.framebufs[centreCornerId].screencodes[0]).character,
          colour: flowerCentreCorners.framebufs[centreCornerId].colors[0]
        }
        // CENTRAL PETAL 1
        else if (r == 4 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[1]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[1]
        }
        else if (r == 4 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[2]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[2]
        }
        // CORNER 2
        else if (r == 4 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentreCorners.framebufs[centreCornerId].screencodes[3]).character,
          colour: flowerCentreCorners.framebufs[centreCornerId].colors[3]
        }
        // PETAL1
        else if (r == 4 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[11]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[11]
        }
        // PETAL2
        else if (r == 4 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[23]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[23]
        }
        // PETAL3
        else if (r == 4 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[58]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[58]
        }
        else if (r == 4 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[59]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[59]
        }

        // PETAL3
        else if (r == 5 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[60]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[60]
        }
        else if (r == 5 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[61]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[61]
        }
        // PETAL2
        else if (r == 5 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[24]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[24]
        }
        // PETAL1
        else if (r == 5 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[12]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[12]
        }
        // CENTRAL PETAL 2 TOP
        else if (r == 5 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[4]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[4]
        }
        // CENTRE TOP
        else if (r == 5 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentre.framebufs[centreId].screencodes[0]).character,
          colour: flowerCentre.framebufs[centreId].colors[0]
        }
        else if (r == 5 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentre.framebufs[centreId].screencodes[1]).character,
          colour: flowerCentre.framebufs[centreId].colors[1]
        }
        // CENTRAL PETAL 3 TOP
        else if (r == 5 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[7]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[7]
        }
        // PETAL1
        else if (r == 5 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[17]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[17]
        }
        // PETAL2
        else if (r == 5 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[31]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[31]
        }
        // PETAL3
        else if (r == 5 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[70]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[70]
        }
        else if (r == 5 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[71]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[71]
        }


        // PETAL3
        else if (r == 6 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[72]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[72]
        }
        else if (r == 6 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[73]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[73]
        }
        // PETAL2
        else if (r == 6 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[32]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[32]
        }
        // PETAL1
        else if (r == 6 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[18]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[18]
        }
        // CENTRAL PETAL 2 BOTTOM
        else if (r == 6 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[8]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[8]
        }
        // CENTRE BOTTOM
        else if (r == 6 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentre.framebufs[centreId].screencodes[2]).character,
          colour: flowerCentre.framebufs[centreId].colors[2]
        }
        else if (r == 6 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentre.framebufs[centreId].screencodes[3]).character,
          colour: flowerCentre.framebufs[centreId].colors[3]
        }
        // CENTRAL PETAL 3 BOTTOM
        else if (r == 6 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[11]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[11]
        }
        // PETAL1
        else if (r == 6 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[23]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[23]
        }
        // PETAL2
        else if (r == 6 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[39]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[39]
        }
        // PETAL3
        else if (r == 6 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[82]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[82]
        }
        else if (r == 6 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[83]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[83]
        }


        // PETAL3
        else if (r == 7 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[84]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[84]
        }
        else if (r == 7 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[85]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[85]
        }
        // PETAL2
        else if (r == 7 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[40]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[40]
        }
        // PETAL1
        else if (r == 7 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[24]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[24]
        }
        // CORNER 3
        else if (r == 7 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentreCorners.framebufs[centreCornerId].screencodes[12]).character,
          colour: flowerCentreCorners.framebufs[centreCornerId].colors[12]
        }
        // CENTRAL PETAL 4
        else if (r == 7 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[13]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[13]
        }
        else if (r == 7 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentrePetals.framebufs[centrePetalId].screencodes[14]).character,
          colour: flowerCentrePetals.framebufs[centrePetalId].colors[14]
        }
        // CORNER 4
        else if (r == 7 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerCentreCorners.framebufs[centreCornerId].screencodes[15]).character,
          colour: flowerCentreCorners.framebufs[centreCornerId].colors[15]
        }
        // PETAL1
        else if (r == 7 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[29]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[29]
        }
        // PETAL2
        else if (r == 7 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[47]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[47]
        }
        // PETAL3
        else if (r == 7 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[94]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[94]
        }
        else if (r == 7 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[95]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[95]
        }

        // PETAL3
        else if (r == 8 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[96]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[96]
        }
        else if (r == 8 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[97]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[97]
        }
        else if (r == 8 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[98]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[98]
        }
        // PETAL1
        else if (r == 8 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[30]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[30]
        }
        else if (r == 8 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[31]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[31]
        }
        else if (r == 8 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[32]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[32]
        }
        else if (r == 8 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[33]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[33]
        }
        else if (r == 8 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[34]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[34]
        }
        else if (r == 8 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals1.framebufs[petal1Id].screencodes[35]).character,
          colour: flowerPetals1.framebufs[petal1Id].colors[35]
        }
        // PETAL3
        else if (r == 8 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[105]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[105]
        }
        else if (r == 8 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[106]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[106]
        }
        else if (r == 8 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[107]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[107]
        }

        // PETAL3
        else if (r == 9 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[108]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[108]
        }
        else if (r == 9 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[109]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[109]
        }
        else if (r == 9 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[110]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[110]
        }
        else if (r == 9 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[111]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[111]
        }
        // PETAL2
        else if (r == 9 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[58]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[58]
        }
        else if (r == 9 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[59]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[59]
        }
        else if (r == 9 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[60]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[60]
        }
        else if (r == 9 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals2.framebufs[petal2Id].screencodes[61]).character,
          colour: flowerPetals2.framebufs[petal2Id].colors[61]
        }
        // PETAL3
        else if (r == 9 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[116]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[116]
        }
        else if (r == 9 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[117]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[117]
        }
        else if (r == 9 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[118]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[118]
        }
        else if (r == 9 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[119]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[119]
        }

        // PETAL3
        else if (r == 10 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[120]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[120]
        }
        else if (r == 10 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[121]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[121]
        }
        else if (r == 10 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[122]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[122]
        }
        else if (r == 10 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[123]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[123]
        }
        else if (r == 10 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[124]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[124]
        }
        else if (r == 10 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[125]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[125]
        }
        else if (r == 10 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[126]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[126]
        }
        else if (r == 10 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[127]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[127]
        }
        else if (r == 10 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[128]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[128]
        }
        else if (r == 10 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[129]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[129]
        }
        else if (r == 10 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[130]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[130]
        }
        else if (r == 10 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[131]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[131]
        }

        // PETAL3
        else if (r == 10 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[120]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[120]
        }
        else if (r == 10 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[121]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[121]
        }
        else if (r == 10 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[122]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[122]
        }
        else if (r == 10 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[123]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[123]
        }
        else if (r == 10 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[124]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[124]
        }
        else if (r == 10 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[125]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[125]
        }
        else if (r == 10 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[126]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[126]
        }
        else if (r == 10 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[127]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[127]
        }
        else if (r == 10 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[128]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[128]
        }
        else if (r == 10 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[129]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[129]
        }
        else if (r == 10 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[130]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[130]
        }
        else if (r == 10 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[131]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[131]
        }
        
        // PETAL3
        else if (r == 11 && c == 0) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[132]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[132]
        }
        else if (r == 11 && c == 1) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[133]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[133]
        }
        else if (r == 11 && c == 2) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[134]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[134]
        }
        else if (r == 11 && c == 3) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[135]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[135]
        }
        else if (r == 11 && c == 4) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[136]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[136]
        }
        else if (r == 11 && c == 5) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[137]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[137]
        }
        else if (r == 11 && c == 6) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[138]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[138]
        }
        else if (r == 11 && c == 7) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[139]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[139]
        }
        else if (r == 11 && c == 8) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[140]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[140]
        }
        else if (r == 11 && c == 9) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[141]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[141]
        }
        else if (r == 11 && c == 10) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[142]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[142]
        }
        else if (r == 11 && c == 11) ch = {
          character: petsciiCodes.find((el) => el.code == flowerPetals3.framebufs[petal3Id].screencodes[143]).character,
          colour: flowerPetals3.framebufs[petal3Id].colors[143]
        }

        // STEM
        else if (r >= 12) ch = {
          character: petsciiCodes.find((el) => el.code == flowerStems.framebufs[stemId].screencodes[12 * (r - 12) + c]).character,
          colour: flowerStems.framebufs[stemId].colors[12 * (r - 12) + c]
        }
      
        temp3.push(ch);

        if ((c + 1) % width == 0) {
          temp4.push(temp3);
          temp3 = [];
        }
      }
    }

    var temp5 = [];
    var temp6 = [];

    // change colours
    for (let r = 0; r < temp4.length; r++) {
      for (let c = 0; c < temp4[r].length; c++) {
        var cl = temp4[r][c].colour;

        var colourFromScheme = "white";
        if (cl == 7) colourFromScheme = colourSchemes[colourSchemeId][0]; // yellow
        else if (cl == 8) colourFromScheme = colourSchemes[colourSchemeId][1]; // brown
        else if (cl == 10) colourFromScheme = colourSchemes[colourSchemeId][2]; // red
        else if (cl == 2) colourFromScheme = colourSchemes[colourSchemeId][3]; // red dark
        else if (cl == 13) colourFromScheme = colourSchemes[colourSchemeId][4]; // green
        else if (cl == 5) colourFromScheme = colourSchemes[colourSchemeId][5]; // green dark

        var ch = {
          character: temp4[r][c].character,
          colour: colourFromScheme,
        }

        temp5.push(ch);

        if ((c + 1) % width == 0) {
          temp6.push(temp5);
          temp5 = [];
        }
    }};

    setChars(temp6);
  }, [colourSchemeId, centreId, centreCornerId, centrePetalId, petal1Id, petal2Id, petal3Id, stemId]);

  useEffect(() => { // new flower at load
    newFlower();
  }, []);



  const newFlower = () => { // randomise elements based on seed
    
    let machineId = localStorage.getItem('MachineId'); // get machine id
    if (!machineId) {
      machineId = crypto.randomUUID();
      localStorage.setItem('MachineId', machineId);
    }
    var date = new Date();
    var rand = `${Math.floor((date.getTime()/1000/60 - date.getTimezoneOffset()) / 60 / 24) }`; // one per day

    var rng = new seedrandom(`${machineId}-${rand}`);

    setColourSchemeId(Math.floor(rng() * colourSchemes.length));
    setCentreId(Math.floor(rng() * flowerCentre.framebufs.length));
    setCentreCornerId(Math.floor(rng() * flowerCentreCorners.framebufs.length));
    setCentrePetalId(Math.floor(rng() * flowerCentrePetals.framebufs.length));
    setPetal1Id(Math.floor(rng() * flowerPetals1.framebufs.length));
    setPetal2Id(Math.floor(rng() * flowerPetals2.framebufs.length));
    setPetal3Id(Math.floor(rng() * flowerPetals3.framebufs.length));
    setStemId(Math.floor(rng() * flowerStems.framebufs.length));
  }

  var classNames = "center";

  return (
    <Fragment>
      <pre id={id} className={classNames}>
        {chars.map((r, i) => {
          var row = r.map((ch, j) =>
            <pre key={j} className={`c64 petsciiChar fg-colodore-${ch.colour} bg-colodore-black`}>
              {ch.character}
            </pre>
          )
          return ( <pre key={i}>{row}</pre>)
          }
        )}
      </pre>
      {caption && <div className="width80"><Spacer />{caption}</div>}
    </Fragment>
  );
}


export default Flower;
