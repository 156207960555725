import { Fragment, useEffect, useState } from "react";

import dead from "../ascii/dead.txt";
import untitled from "../ascii/untitled.txt";
import venus from "../ascii/venus.txt";
import xray from "../ascii/xray.txt";
import logo from "../ascii/logo.txt";
import eternityReachingOut from "../ascii/eternity_reaching_out.txt";
import e404 from "../ascii/404.txt";

import x86re from "../ascii/x86re.txt";
import paulie20forbeer from "../ascii/20forbeer.txt";

import Spacer from "./spacer";

const variants = [
  {
    name: "dead",
    data: dead,
  },
  {
    name: "untitled",
    data: untitled,
  },
  {
    name: "venus",
    data: venus,
  },
  {
    name: "xray",
    data: xray,
  },
  {
    name: "logo",
    data: logo,
  },
  {
    name: "eternity_reaching_out",
    data: eternityReachingOut,
  },
  {
    name: "e404",
    data: e404,
  },
  {
    name: "x86re",
    data: x86re,
  },
  {
    name: "20forbeer",
    data: paulie20forbeer,
  },
];

const Ascii = ({art, caption, clickable=false, p2a=false}) => {
  const [image, setImage] = useState([]);
  
  useEffect(() => {
    const variant = variants.find(e => e.name === art);
    fetch(variant.data)
      .then(r => r.text())
      .then(text => {
        setImage(text);
      });
  }, []);

  return (
    <Fragment>
      <pre className={`center ${clickable && "clickable"} ${p2a && "p2a"}`}>
        {image}
      </pre>
      {caption && <div className="width80"><Spacer />{caption}</div>}
    </Fragment>
  );
}


export default Ascii;
