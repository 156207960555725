import { useEffect, useState } from "react";

const Loader = ({length=3, delay=1000, spaceWidth=0, spacingChar=" ", loaderChar="."}) => {
  const [string, setString] = useState("".padEnd(length, " "));
  const [step, setStep] = useState(1);

  useEffect(async () => {  
    setStep((step + 1) % (length * 2));

    const delayFunction = ms => new Promise(res => setTimeout(res, ms));
    await delayFunction(delay);

    if (step == 0) setString("".padEnd(length, " "))
    else if (step <= length) setString("".padEnd(step, loaderChar).padEnd(length, " "))
    else setString("".padEnd(step - length, " ").padEnd(length, loaderChar))
  }, [string])

  const insertSpaces = (aString, spaceWidth=spaceWidth, spacingChar=spacingChar) => aString.split("").join("".padEnd(spaceWidth, spacingChar));

  return (
    <pre className="center">
      {insertSpaces(string, spaceWidth, spacingChar)}
    </pre>
  )
}

export default Loader;