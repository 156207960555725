import { Fragment, useEffect, useState } from "react";
import Spacer from "../components/spacer";
import Resizer from "react-image-file-resizer";
import Clickable from "../components/clickable";
import Loader from "../components/loader";
import MyLink from "../components/myLink";

const LIMIT = 85;
const CHARS = {
  white: "@",
  gray: "*",
  black: ".",
}

const SETTINGS = {
  resolution: {
    delta: 2,
  },
  white: {
    delta: 10,
    limit: 256 - 120,
  },
  black: {
    delta: 10,
    limit: 120,
  }
}

const Polaroid = () => {
  const [origImage, setOrigImage] = useState();
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState(); // only for displaying
  const [resolution, setResolution] = useState(36);
  const [white, setWhite] = useState(255 - LIMIT);
  const [black, setBlack] = useState(LIMIT);
  const [asciiPixels, setAsciiPixels] = useState([]);
  const [asciiImage, setAsciiImage] = useState("");
  const [dimensions, setDimensions] = useState({});
  
  const onChangeImage = async (e) => {
    setOrigImage(e.target.files[0]);
    setImage(await resizeFile(e.target.files[0], resolution));
  }

  useEffect(async () => {
    if (!image) return;
    setImage(await resizeFile(origImage, resolution));
    setImageUrl(image);
    // console.log(image);
    parseDataImage(image);
  }, [image, resolution, black, white, dimensions]);

  const onImageLoad = ({target:img}) => {
    const newDimensions = {
      height: img.naturalHeight,
      width: img.naturalWidth,
    }
    console.log(newDimensions);
    setDimensions(newDimensions);
  }
  const resizeFile = (file, res) => new Promise(resolve => {
    Resizer.imageFileResizer(file, res, res, 'PNG', 100, 0,
    uri => {
      resolve(uri);
    }, 'base64', res, res);
  });

  const PNGReader = require('png.js');
  const parseDataImage = (data) => {
    const base64 = data.split(',')[1];
    const bytes = atob(base64); // Base64 Decode
    const png = new PNGReader(bytes);
    png.parse((err, png) => {
      const grayscalePixels = [];
      for (var x = 0; x < png.pixels.length; x = x + 4) {
        grayscalePixels.push(Math.round((png.pixels[x] + png.pixels[x + 1] + png.pixels[x + 2]) / 3));
      }
      pixelsToAscii(grayscalePixels);
    });
  }
  const pixelsToAscii = (pixels) => {
    const newAsciiPixels = [];
    pixels.forEach(e => {
      newAsciiPixels.push(e >= white ? CHARS.white : e <= black ? CHARS.black : CHARS.gray);
    });
    setAsciiPixels(newAsciiPixels);
  }

  useEffect(async () => {
    if (asciiPixels.length <= 0) return;

    // const delay = 1000;
    // const delayFunction = ms => new Promise(res => setTimeout(res, ms));
    // await delayFunction(delay);

    var newAsciiImage = ".-";
      for (var y = 0; y < resolution + 2; y++) {
        newAsciiImage += "--";
      }
    newAsciiImage += ".\n";
    newAsciiImage += "| ";
      for (var y = 0; y < resolution + 2; y++) {
        newAsciiImage += "  ";
      }
    newAsciiImage += "|\n";
    for(var x = 0; x < resolution * resolution; x++) {
      if (x % resolution == 0) {
        newAsciiImage += "|   ";
        newAsciiImage += `${asciiPixels[x]} `;
      }
      else if (x % resolution == resolution - 1) {
        newAsciiImage += `${asciiPixels[x]} `;
        newAsciiImage += "  |\n";
      }
      else newAsciiImage += `${asciiPixels[x]} `;
    }
    
    for (var l = 0; l < Math.round(resolution / 5); l++) {
      newAsciiImage += "| ";
      if (l == Math.round(resolution / 5) - 2) {
        if (resolution > 20) {
          for (var y = 0; y < resolution + 2 - 11 ; y++) {
            newAsciiImage += "  ";
          }
          newAsciiImage += " littlebitspace.com   ";
        }
        else if (resolution > 12) {
          for (var y = 0; y < resolution + 2 - 9; y++) {
            newAsciiImage += "  ";
          }
          newAsciiImage += " littlebitspace   ";
        }
        else {
          for (var y = 0; y < resolution + 2 - 3; y++) {
            newAsciiImage += "  ";
          }
          newAsciiImage += "lbs   ";
        }
      }
      else {
        for (var y = 0; y < resolution + 2; y++) {
          newAsciiImage += "  ";
        }
      }
      newAsciiImage += "|\n";
    }
    newAsciiImage += "'-";
      for (var y = 0; y < resolution + 2; y++) {
        newAsciiImage += "--";
      }
    newAsciiImage += "'\n";
    
    setAsciiImage(newAsciiImage);

  }, [asciiPixels, resolution]);

  return (
    <Fragment>
      <div className="center">ASCII Polaroid Creator</div>
      <Spacer />
      <div className="width80">
        This ASCII polaroid creator was inspired by the series of polaroids, which I did back in 2021. I wanted to make the style available for everybody and thus had to figure out how to accomplish similar end-results, which I had previously done by hand, programmatically. This tool is 100% free to use and the resulting polaroid images can be used however you like as long as I am given credit. <MyLink title="#asciipolaroid" url={"https://www.instagram.com/explore/tags/asciipolaroid/"} />
      </div>
      <Spacer />
      <div className="width80">
        In its current form, this tool forcefully crops everything into squares. For the best results, make sure you are using square-cropped images. I won't collect any of your data and all of the processing happens on the front-end.
      </div>
      <Spacer size={2} />
      <div className="width80">
        <form>
          <input className="imageSelector"
            type="file"
            accept="image/jpg image/png"
            onChange={onChangeImage}
          />
        </form>
        {/* <div className="imageDisplay" hidden>
          <img id="image" className="image" src={imageUrl} onLoad={onImageLoad} />
        </div> */}
        <Spacer />
        {asciiImage ?
          <div>
            <pre className="center">
              {asciiImage}
            </pre>
            <Spacer />
            <div className="settingsBar width80">
              <div>
                <Clickable
                  disabled={resolution <= 8}
                  title="[-]"
                  onClick={() => setResolution(resolution - SETTINGS.resolution.delta)}
                />
                &nbsp;Resolution&nbsp;
                <Clickable
                  disabled={resolution >= 50}
                  title="[+]"
                  onClick={() => setResolution(resolution + SETTINGS.resolution.delta)}
                  />
              </div>
              <div>
                <Clickable
                  disabled={white + SETTINGS.white.delta >= 255}
                  title="[-]"
                  onClick={() => setWhite(white + SETTINGS.white.delta)}
                  />
                &nbsp;White&nbsp;
                <Clickable
                  disabled={white - SETTINGS.white.delta <= black}
                  title="[+]"
                  onClick={() => setWhite(white - SETTINGS.white.delta)}
                  />
              </div>
              <div>
                <Clickable
                  disabled={black - SETTINGS.black.delta <= 0}
                  title="[-]"
                  onClick={() => setBlack(black - SETTINGS.black.delta)}
                  />
                &nbsp;Black&nbsp;
                <Clickable
                  // disabled={black + SETTINGS.black.delta >= SETTINGS.black.limit}
                  disabled={black + SETTINGS.black.delta >= white}
                  title="[+]"
                  onClick={() => setBlack(black + SETTINGS.black.delta)}
                  />
              </div>
            </div>
          </div>
        : <Loader
            length={1}
            delay={1000}
            loaderChar="?"
          />
        }
        
        
      </div>
      <Spacer size={2} />
      <div className="center">Tips</div>
      <Spacer />
      <div className="width80">
        After choosing a file, you can fine-tune the contrast of the consequent polaroid image with the controls below outcome. You can also change the resolution of the resulting ASCII image however you like. Nevertheless, I would advise keeping the width of it lower than the width of these  paragraphs.
      </div>
      <Spacer />
      <div className="width80">
        To save or share it, simply take a screenshot or copy the polaroid as it is only text.
      </div>
    </Fragment>
  );
}

export default Polaroid;
