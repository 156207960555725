import { useState, Fragment, useEffect } from "react";

import Clickable from "../components/clickable";
import Petscii from "../components/petscii";
import Spacer from "../components/spacer";
import { tarotDeck, divModes, suits } from "../data/tarot";
import MyLink from "../components/myLink";

const TarotSimulator = () => {
  const [spread, setSpread] = useState([])
  const [divMode, setDivMode] = useState(divModes[0])
  const [browseModeOn, setBrowseModeOn] = useState(false)
  const [browseIndex, setBrowseIndex] = useState(0)
  const [browseCard, setBrowseCard] = useState(tarotDeck[browseIndex])
  const [currentSuit, setCurrentSuit] = useState(suits[0])

  function shuffle(array) {
    let currentIndex = array.length, randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  const handleNewSpread = () => {
    var deck = [];
    for (var card of tarotDeck) deck.push({isRevealed: false, ...card});
    var deck = shuffle(deck);
    var hand = [];
    const amount = 3;

    for (var i = 0; i < amount; i++) {
      var card = deck.pop();
      card.isUpright = Math.round(Math.random());
      hand.push(card);
    }
    setSpread(hand);
  };
  // const handleClear = () => setSpread([]);
  const handleClear = () => location.reload();

  const handleChangeDivMode = (newDivMode) => setDivMode(newDivMode);
  
  const handleToggleBrowseMode = () => setBrowseModeOn(!browseModeOn);

  const handleSetSuit = (suit) => {
    setCurrentSuit(suit);
    setBrowseIndex(suit.startIndex);
  };

  const handleSetIndex = (index) => {
    index = index >= tarotDeck.length ? 0 : index < 0 ? tarotDeck.length - 1 : index;
    setBrowseIndex(index);
    setCurrentSuit(suits.find((suit) => suit.startIndex <= index && suit.endIndex >= index));
  };

  useEffect(() => {
    setBrowseCard(tarotDeck[browseIndex]);
  }, [browseIndex]);

  return (
    <Fragment>
      <div className="center">Tarot Spread Simulator</div>
      <Spacer />
      <div  className="width80">
        This Tarot Simulator utilises the PETSCII Tarot deck. Who would've guessed that? Currently, it supports the classic three-card linear spread. I'd be glad to hear any improvement ideas you might have!
      </div>
      <Spacer size={2} />

      <div className="center">Instructions</div>
      <Spacer />
      <div  className="width80">
        First, think about an open-ended question you'd like an answer to. Select one of the modes below that's best suited for your inquiry. Then hit <Clickable disabled={spread.length > 0 || browseModeOn} title="New spread" onClick={handleNewSpread} /> to deal three cards. To reveal or conceal a card, tap or click it. Use your intuition to interpret the combination of cards you get. <Clickable disabled={spread.length == 0 || browseModeOn} title="Clear" onClick={handleClear} /> the table and do it again! Not into interpreting Tarot spreads? You can also <Clickable title="browse" onClick={handleToggleBrowseMode} /> the deck instead.
      </div>
      <Spacer />
      {!browseModeOn ? 
        !spread.length > 0 ?
          <Fragment>
            <div className="width80">
              Modes:
              <Spacer />
              <ul>
                {divModes.map((m) =>
                  <li key={m.name}>
                    <Clickable
                      padding
                      title={m.slots.join(" / ")}
                      active={divMode === m}
                      onClick={() => handleChangeDivMode(m)}
                      /> 
                  </li>
                )}
              </ul>
              <Spacer />
              <Clickable title="New spread" onClick={handleNewSpread} /> or <Clickable title="browse cards" onClick={handleToggleBrowseMode} />
            </div>
          </Fragment> :
          <Fragment>
            <Spacer />
            <div className="center">
              Your cards
              <Spacer />
              {spread.map((card, index) => 
                <div className="width34" style={{display: "inline-block"}} key={card.name + Date.now()}>
                  <div className="center">{divMode.slots[index]}</div>
                  <Spacer />
                      <Petscii
                        simulator
                        animated
                        reversed={!card.isUpright}
                        art={card.imgName}
                        art2="cardback"
                        caption={
                          <Fragment>
                            <div className="center">{card.name}</div>
                            <Spacer />
                            {card.isUpright ? "Upright" : "Reversed"}
                            <ul>
                              {card.isUpright ? 
                                card.upright.map((meaning, index) => meaning.length == 0 ? <br key={index} /> : <li key={index}>{meaning}</li>) :
                                card.reversed.map((meaning, index) => meaning.length == 0 ? <br key={index} /> : <li key={index}>{meaning}</li >)
                              }
                            </ul>
                          </Fragment>
                        }
                      />
                    <Spacer />
                </div>
              )}
            </div>
            <div className="width80">
              <Spacer />
              <Clickable title="Clear" onClick={handleClear} />
            </div>
          </Fragment> :
        <div className="width80">
          <Spacer />
          <div className="center">
            <div className="left">
              <Clickable title="Prev" onClick={() => handleSetIndex(browseIndex - 1)} />
            </div>
            <div className="inlineBlock">Browse cards</div>
            <div className="right">
              <Clickable title="Next" onClick={() => handleSetIndex(browseIndex + 1)} />
            </div>
          </div>
          <Spacer />
          <div className="center">
            {suits.map((suit, index) =>
              <Fragment key={suit.name}>
                <Clickable active={suit == currentSuit} padding title={suit.name} onClick={() => handleSetSuit(suit)} />
                {index !== suits.length - 1 && <span> | </span>}
              </Fragment>
            )}
            <Spacer />
            {currentSuit.cardNumbers.map((cardNum, index) => 
              <Fragment key={index}>
                <Clickable 
                  title={cardNum}
                  padding
                  active={index + currentSuit.startIndex == browseIndex}
                  onClick={() => handleSetIndex(index + currentSuit.startIndex)}
                />
              </Fragment>
            )}
          </div>
          <Spacer />
          <div className="center">
            <div className="width34" style={{display: "inline-block"}}>
              <div key={Date.now()}>
                <Petscii
                  simulator
                  art={browseCard.imgName}
                  caption={
                    <Fragment>
                      <div className="center">{browseCard.name}</div>
                      <Spacer />
                      Upright
                      <ul>
                        {browseCard.upright.map((meaning, index) => meaning.length == 0 ? <br key={index} /> : <li key={index}>{meaning}</li>)}
                      </ul>
                      <Spacer />
                      Reversed
                      <ul>
                        {browseCard.reversed.map((meaning, index) => meaning.length == 0 ? <br key={index} /> : <li key={index}>{meaning}</li>)}
                      </ul>
                    </Fragment>
                  }
                />
              </div>
            </div>
          </div>
          <Spacer />
          <Clickable title="Quit browsing" onClick={handleToggleBrowseMode} />
        </div>
      }
      <Spacer size={2} />

      <div className="center">Mobile users</div>
      <Spacer />
      <div className="width80">
        If you are using this simulator on a mobile device or otherwise small screen you might want to consider diminishing the font size: the controls are at the top of the page. This should let you view all three cards side by side. However, if you would like to explore the details of the cards you might find it more favourable to increase the font size. It's up to you.
      </div>
      <Spacer size={2} />

      <div className="center">Disclaimer</div>
      <Spacer />
      <div className="width80">
        The Tarot simulator does not do divination. The sole purpose of this software is to showcase the deck I designed. Nevertheless, you can use this simulator to get new perspectives on the thoughts occupying your mind. Exercise cautious when you are interpreting the cards. My knowledge on the topic is limited so I advise you to do your own research by typing the following words into your favourite search engine: <MyLink title="tarot cards interpretation" url="https://search.brave.com/search?q=tarot+card+interpretation" />.
      </div>
    </Fragment>
  );
}

export default TarotSimulator;
