import { Fragment } from "react";

const Clickable = ({title="Button", onClick, padding, active, disabled}) => {
  var classNames = "clickable";
  if (active) classNames += " active";
  if (disabled) classNames += " disabled";
  return (
    <button
      className={classNames}
      onClick={!disabled ? onClick : null}
    >
      {padding && <Fragment>&nbsp;</Fragment>}
      {title}
      {padding && <Fragment>&nbsp;</Fragment>}
    </button>
  );
};

export default Clickable;
