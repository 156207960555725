import { Fragment, useEffect, useRef, useState } from "react";

import palette from "../petscii/palette.json";
import garden from "../petscii/garden.json";
import composition from "../petscii/composition.json";
import realhackhistory from "../petscii/realhackhistory.json";
import theLastGambit from "../petscii/the_last_gambit.json"

import cardback from "../petscii/tarot/cardback.json";
import t0000 from "../petscii/tarot/t0000.json";
import t0001 from "../petscii/tarot/t0001.json";
import t0002 from "../petscii/tarot/t0002.json";
import t0003 from "../petscii/tarot/t0003.json";
import t0004 from "../petscii/tarot/t0004.json";
import t0005 from "../petscii/tarot/t0005.json";
import t0006 from "../petscii/tarot/t0006.json";
import t0007 from "../petscii/tarot/t0007.json";
import t0008 from "../petscii/tarot/t0008.json";
import t0009 from "../petscii/tarot/t0009.json";
import t0010 from "../petscii/tarot/t0010.json";
import t0011 from "../petscii/tarot/t0011.json";
import t0012 from "../petscii/tarot/t0012.json";
import t0013 from "../petscii/tarot/t0013.json";
import t0014 from "../petscii/tarot/t0014.json";
import t0015 from "../petscii/tarot/t0015.json";
import t0016 from "../petscii/tarot/t0016.json";
import t0017 from "../petscii/tarot/t0017.json";
import t0018 from "../petscii/tarot/t0018.json";
import t0019 from "../petscii/tarot/t0019.json";
import t0020 from "../petscii/tarot/t0020.json";
import t0021 from "../petscii/tarot/t0021.json";
import t0101 from "../petscii/tarot/t0101.json";
import t0102 from "../petscii/tarot/t0102.json";
import t0103 from "../petscii/tarot/t0103.json";
import t0104 from "../petscii/tarot/t0104.json";
import t0105 from "../petscii/tarot/t0105.json";
import t0106 from "../petscii/tarot/t0106.json";
import t0107 from "../petscii/tarot/t0107.json";
import t0108 from "../petscii/tarot/t0108.json";
import t0109 from "../petscii/tarot/t0109.json";
import t0110 from "../petscii/tarot/t0110.json";
import t0111 from "../petscii/tarot/t0111.json";
import t0112 from "../petscii/tarot/t0112.json";
import t0113 from "../petscii/tarot/t0113.json";
import t0114 from "../petscii/tarot/t0114.json";
import t0201 from "../petscii/tarot/t0201.json";
import t0202 from "../petscii/tarot/t0202.json";
import t0203 from "../petscii/tarot/t0203.json";
import t0204 from "../petscii/tarot/t0204.json";
import t0205 from "../petscii/tarot/t0205.json";
import t0206 from "../petscii/tarot/t0206.json";
import t0207 from "../petscii/tarot/t0207.json";
import t0208 from "../petscii/tarot/t0208.json";
import t0209 from "../petscii/tarot/t0209.json";
import t0210 from "../petscii/tarot/t0210.json";
import t0211 from "../petscii/tarot/t0211.json";
import t0212 from "../petscii/tarot/t0212.json";
import t0213 from "../petscii/tarot/t0213.json";
import t0214 from "../petscii/tarot/t0214.json";
import t0301 from "../petscii/tarot/t0301.json";
import t0302 from "../petscii/tarot/t0302.json";
import t0303 from "../petscii/tarot/t0303.json";
import t0304 from "../petscii/tarot/t0304.json";
import t0305 from "../petscii/tarot/t0305.json";
import t0306 from "../petscii/tarot/t0306.json";
import t0307 from "../petscii/tarot/t0307.json";
import t0308 from "../petscii/tarot/t0308.json";
import t0309 from "../petscii/tarot/t0309.json";
import t0310 from "../petscii/tarot/t0310.json";
import t0311 from "../petscii/tarot/t0311.json";
import t0312 from "../petscii/tarot/t0312.json";
import t0313 from "../petscii/tarot/t0313.json";
import t0314 from "../petscii/tarot/t0314.json";
import t0401 from "../petscii/tarot/t0401.json";
import t0402 from "../petscii/tarot/t0402.json";
import t0403 from "../petscii/tarot/t0403.json";
import t0404 from "../petscii/tarot/t0404.json";
import t0405 from "../petscii/tarot/t0405.json";
import t0406 from "../petscii/tarot/t0406.json";
import t0407 from "../petscii/tarot/t0407.json";
import t0408 from "../petscii/tarot/t0408.json";
import t0409 from "../petscii/tarot/t0409.json";
import t0410 from "../petscii/tarot/t0410.json";
import t0411 from "../petscii/tarot/t0411.json";
import t0412 from "../petscii/tarot/t0412.json";
import t0413 from "../petscii/tarot/t0413.json";
import t0414 from "../petscii/tarot/t0414.json";

import Spacer from "./spacer";

const variants = [
  {
    name: "palette",
    data: palette,
    palette: "petmate",
  },
  {
    name: "garden",
    data: garden,
    palette: "colodore",
  },
  {
    name: "composition",
    data: composition,
    palette: "petmate",
  },
  {
    name: "realhackhistory",
    data: realhackhistory,
    palette: "colodore",
  },
  {
    name: "the last gambit",
    data: theLastGambit,
    palette: "colodore",
  },
  { name: "cardback", data: cardback, palette: "petmate"},
  { name: "t0000", data: t0000, palette: "petmate", },
  { name: "t0001", data: t0001, palette: "petmate", },
  { name: "t0002", data: t0002, palette: "petmate", },
  { name: "t0003", data: t0003, palette: "petmate", },
  { name: "t0004", data: t0004, palette: "petmate", },
  { name: "t0005", data: t0005, palette: "petmate", },
  { name: "t0006", data: t0006, palette: "petmate", },
  { name: "t0007", data: t0007, palette: "petmate", },
  { name: "t0008", data: t0008, palette: "petmate", },
  { name: "t0009", data: t0009, palette: "petmate", },
  { name: "t0010", data: t0010, palette: "petmate", },
  { name: "t0011", data: t0011, palette: "petmate", },
  { name: "t0012", data: t0012, palette: "petmate", },
  { name: "t0013", data: t0013, palette: "petmate", },
  { name: "t0014", data: t0014, palette: "petmate", },
  { name: "t0015", data: t0015, palette: "petmate", },
  { name: "t0016", data: t0016, palette: "petmate", },
  { name: "t0017", data: t0017, palette: "petmate", },
  { name: "t0018", data: t0018, palette: "petmate", },
  { name: "t0019", data: t0019, palette: "petmate", },
  { name: "t0020", data: t0020, palette: "petmate", },
  { name: "t0021", data: t0021, palette: "petmate", },
  { name: "t0101", data: t0101, palette: "petmate", },
  { name: "t0102", data: t0102, palette: "petmate", },
  { name: "t0103", data: t0103, palette: "petmate", },
  { name: "t0104", data: t0104, palette: "petmate", },
  { name: "t0105", data: t0105, palette: "petmate", },
  { name: "t0106", data: t0106, palette: "petmate", },
  { name: "t0107", data: t0107, palette: "petmate", },
  { name: "t0108", data: t0108, palette: "petmate", },
  { name: "t0109", data: t0109, palette: "petmate", },
  { name: "t0110", data: t0110, palette: "petmate", },
  { name: "t0111", data: t0111, palette: "petmate", },
  { name: "t0112", data: t0112, palette: "petmate", },
  { name: "t0113", data: t0113, palette: "petmate", },
  { name: "t0114", data: t0114, palette: "petmate", },
  { name: "t0201", data: t0201, palette: "petmate", },
  { name: "t0202", data: t0202, palette: "petmate", },
  { name: "t0203", data: t0203, palette: "petmate", },
  { name: "t0204", data: t0204, palette: "petmate", },
  { name: "t0205", data: t0205, palette: "petmate", },
  { name: "t0206", data: t0206, palette: "petmate", },
  { name: "t0207", data: t0207, palette: "petmate", },
  { name: "t0208", data: t0208, palette: "petmate", },
  { name: "t0209", data: t0209, palette: "petmate", },
  { name: "t0210", data: t0210, palette: "petmate", },
  { name: "t0211", data: t0211, palette: "petmate", },
  { name: "t0212", data: t0212, palette: "petmate", },
  { name: "t0213", data: t0213, palette: "petmate", },
  { name: "t0214", data: t0214, palette: "petmate", },
  { name: "t0301", data: t0301, palette: "petmate", },
  { name: "t0302", data: t0302, palette: "petmate", },
  { name: "t0303", data: t0303, palette: "petmate", },
  { name: "t0304", data: t0304, palette: "petmate", },
  { name: "t0305", data: t0305, palette: "petmate", },
  { name: "t0306", data: t0306, palette: "petmate", },
  { name: "t0307", data: t0307, palette: "petmate", },
  { name: "t0308", data: t0308, palette: "petmate", },
  { name: "t0309", data: t0309, palette: "petmate", },
  { name: "t0310", data: t0310, palette: "petmate", },
  { name: "t0311", data: t0311, palette: "petmate", },
  { name: "t0312", data: t0312, palette: "petmate", },
  { name: "t0313", data: t0313, palette: "petmate", },
  { name: "t0314", data: t0314, palette: "petmate", },
  { name: "t0401", data: t0401, palette: "petmate", },
  { name: "t0402", data: t0402, palette: "petmate", },
  { name: "t0403", data: t0403, palette: "petmate", },
  { name: "t0404", data: t0404, palette: "petmate", },
  { name: "t0405", data: t0405, palette: "petmate", },
  { name: "t0406", data: t0406, palette: "petmate", },
  { name: "t0407", data: t0407, palette: "petmate", },
  { name: "t0408", data: t0408, palette: "petmate", },
  { name: "t0409", data: t0409, palette: "petmate", },
  { name: "t0410", data: t0410, palette: "petmate", },
  { name: "t0411", data: t0411, palette: "petmate", },
  { name: "t0412", data: t0412, palette: "petmate", },
  { name: "t0413", data: t0413, palette: "petmate", },
  { name: "t0414", data: t0414, palette: "petmate", },
  
];

const petsciiCodes = [
  { code: 32, character: "\ue1a0" },
  { code: 1, character: "A" },
  { code: 2, character: "B" },
  { code: 3, character: "C" },
  { code: 4, character: "D" },
  { code: 5, character: "E" },
  { code: 6, character: "F" },
  { code: 7, character: "G" },
  { code: 8, character: "H" },
  { code: 9, character: "I" },
  { code: 10, character: "J" },
  { code: 11, character: "K" },
  { code: 12, character: "L" },
  { code: 13, character: "M" },
  { code: 14, character: "N" },
  { code: 15, character: "O" },
  { code: 16, character: "P" },
  { code: 17, character: "Q" },
  { code: 18, character: "R" },
  { code: 19, character: "S" },
  { code: 20, character: "T" },
  { code: 21, character: "U" },
  { code: 22, character: "V" },
  { code: 23, character: "W" },
  { code: 24, character: "X" },
  { code: 25, character: "Y" },
  { code: 26, character: "Z" },
  { code: 46, character: "." },
  { code: 44, character: "," },
  { code: 59, character: ";" },
  { code: 33, character: "!" },
  { code: 63, character: "?" },
  { code: 48, character: "0" },
  { code: 49, character: "1" },
  { code: 50, character: "2" },
  { code: 51, character: "3" },
  { code: 52, character: "4" },
  { code: 53, character: "5" },
  { code: 54, character: "6" },
  { code: 55, character: "7" },
  { code: 56, character: "8" },
  { code: 57, character: "9" },
  { code: 34, character: '"' },
  { code: 35, character: "#" },
  { code: 36, character: "$" },
  { code: 37, character: "%" },
  { code: 38, character: "&" },
  { code: 39, character: "'" },
  { code: 112, character: "┌" },
  { code: 110, character: "┐" },
  { code: 108, character: "▗" },
  { code: 123, character: "▖" },
  { code: 85, character: "╭" },
  { code: 73, character: "╮" },
  { code: 79, character: "\ue0cf" },
  { code: 80, character: "\ue0d0" },
  { code: 113, character: "┴" },
  { code: 114, character: "┬" },
  { code: 40, character: "(" },
  { code: 41, character: ")" },
  { code: 60, character: "<" },
  { code: 62, character: ">" },
  { code: 78, character: "╱" },
  { code: 77, character: "╲" },
  { code: 109, character: "└" },
  { code: 125, character: "┘" },
  { code: 124, character: "▝" },
  { code: 126, character: "▘" },
  { code: 74, character: "╰" },
  { code: 75, character: "╯" },
  { code: 76, character: "\ue06c" },
  { code: 122, character: "\ue0ba" },
  { code: 107, character: "├" },
  { code: 115, character: "┤" },
  { code: 27, character: "[" },
  { code: 29, character: "]" },
  { code: 31, character: "←" },
  { code: 30, character: "↑" },
  { code: 95, character: "◥" },
  { code: 105, character: "◤" },
  { code: 100, character: "▁" },
  { code: 111, character: "▂" },
  { code: 121, character: "▃" },
  { code: 98, character: "▄" },
  { code: 120, character: "\ue1b8" },
  { code: 119, character: "\ue1f7" },
  { code: 99, character: "▔" },
  { code: 116, character: "▎" },
  { code: 101, character: "▎" },
  { code: 117, character: "▍" },
  { code: 97, character: "▌" },
  { code: 118, character: "\ue0f6" },
  { code: 103, character: "\ue1ea" },
  { code: 106, character: "\ue1ea" },
  { code: 91, character: "┼" },
  { code: 43, character: "+" },
  { code: 82, character: "\ue072" },
  { code: 70, character: "\ue0c6" },
  { code: 64, character: "─" },
  { code: 45, character: "-" },
  { code: 67, character: "─" },
  { code: 68, character: "\ue0c4" },
  { code: 69, character: "\ue0c5" },
  { code: 84, character: "\ue074" },
  { code: 71, character: "\ue067" },
  { code: 66, character: "|" },
  { code: 93, character: "|" },
  { code: 72, character: "\ue068", },
  { code: 89, character: "\ue0d9", },
  { code: 47, character: "/" },
  { code: 86, character: "╳" },
  { code: 42, character: "*" },
  { code: 61, character: "=" },
  { code: 58, character: ":" },
  { code: 28, character: "£" },
  { code: 0, character: "@" },
  { code: 127, character: "▚" },
  { code: 104, character: "\ue0a8" },
  { code: 92, character: "\ue17c" },
  { code: 102, character: "▒" },
  { code: 81, character: "•" },
  { code: 87, character: "○" },
  { code: 65, character: "♠" },
  { code: 83, character: "♥" },
  { code: 88, character: "♣" },
  { code: 90, character: "♦" },
  { code: 94, character: "π" },
  { code: 96, character: "\0" },
  { code: 160, character: "\ue220" },
  { code: 129, character: "\ue241" },
  { code: 130, character: "\ue242" },
  { code: 131, character: "\ue243" },
  { code: 132, character: "\ue244" },
  { code: 133, character: "\ue245" },
  { code: 134, character: "\ue246" },
  { code: 135, character: "\ue247" },
  { code: 136, character: "\ue248" },
  { code: 137, character: "\ue249" },
  { code: 138, character: "\ue24a" },
  { code: 139, character: "\ue24b" },
  { code: 140, character: "\ue24c" },
  { code: 141, character: "\ue24d" },
  { code: 142, character: "\ue24e" },
  { code: 143, character: "\ue24f" },
  { code: 144, character: "\ue250" },
  { code: 145, character: "\ue251" },
  { code: 146, character: "\ue252" },
  { code: 147, character: "\ue253" },
  { code: 148, character: "\ue254" },
  { code: 149, character: "\ue255" },
  { code: 150, character: "\ue256" },
  { code: 151, character: "\ue257" },
  { code: 152, character: "\ue258" },
  { code: 153, character: "\ue259" },
  { code: 154, character: "\ue25a" },
  { code: 174, character: "\ue22e" },
  { code: 172, character: "\ue22c" },
  { code: 187, character: "\ue23b" },
  { code: 161, character: "\ue221" },
  { code: 191, character: "\ue23f" },
  { code: 176, character: "\ue230" },
  { code: 177, character: "\ue231" },
  { code: 178, character: "\ue232" },
  { code: 179, character: "\ue233" },
  { code: 180, character: "\ue234" },
  { code: 181, character: "\ue235" },
  { code: 182, character: "\ue236" },
  { code: 183, character: "\ue237" },
  { code: 184, character: "\ue238" },
  { code: 185, character: "\ue239" },
  { code: 162, character: '\ue222' },
  { code: 163, character: "\ue223" },
  { code: 164, character: "\ue224" },
  { code: 165, character: "\ue225" },
  { code: 166, character: "\ue226" },
  { code: 167, character: "\ue227" },
  { code: 240, character: "\ue2b0" },
  { code: 238, character: "\ue2ae" },
  { code: 236, character: "\ue2ac" },
  { code: 251, character: "\ue2bb" },
  { code: 213, character: "\ue275" },
  { code: 201, character: "\ue269" },
  { code: 207, character: "\ue26f" },
  { code: 208, character: "\ue270" },
  { code: 241, character: "\ue2b1" },
  { code: 242, character: "\ue2b2" },
  { code: 168, character: "\ue228" },
  { code: 169, character: "\ue229" },
  { code: 188, character: "\ue23c" },
  { code: 190, character: "\ue23e" },
  { code: 206, character: "\ue2ce" },
  { code: 205, character: "\ue2cd" },
  { code: 237, character: "\ue2ad" },
  { code: 253, character: "\ue2bd" },
  { code: 252, character: "\ue2bc" },
  { code: 254, character: "\ue2be" },
  { code: 202, character: "\ue26a" },
  { code: 203, character: "\ue26b" },
  { code: 204, character: "\ue2cc" },
  { code: 250, character: "\ue2fa" },
  { code: 235, character: "\ue2ab" },
  { code: 243, character: "\ue2b3" },
  { code: 155, character: "\ue25b" },
  { code: 157, character: "\ue25d" },
  { code: 159, character: "\ue25f" },
  { code: 158, character: "\ue25e" },
  { code: 223, character: "\ue27f" },
  { code: 233, character: "\ue2a9" },
  { code: 228, character: "\ue2e4" },
  { code: 239, character: "\ue2ef" },
  { code: 249, character: "\ue2f9" },
  { code: 226, character: "\ue2e2" },
  { code: 248, character: "▅", },
  { code: 247, character: "▆", },
  { code: 227, character: "\ue2e3" },
  { code: 244, character: "\ue2e5" },
  { code: 229, character: "\ue2e5" },
  { code: 245, character: "\ue2b5" },
  { code: 225, character: "\ue2e1" },
  { code: 246, character: "▋", },
  { code: 231, character: "▊", },
  { code: 234, character: "▊", },
  { code: 219, character: "\ue2db" },
  { code: 171, character: "\ue22b" },
  { code: 210, character: "\ue272" },
  { code: 198, character: "\ue2c6" },
  { code: 192, character: "\ue263" },
  { code: 173, character: "\ue32d" },
  { code: 195, character: "\ue263" },
  { code: 196, character: "\ue2c4" },
  { code: 197, character: "\ue2c5" },
  { code: 212, character: "\ue274" },
  { code: 199, character: "\ue2c7" },
  { code: 194, character: "\ue2dd" },
  { code: 221, character: "\ue2dd" },
  { code: 200, character: "\ue2c8" },
  { code: 217, character: "\ue2d9" },
  { code: 175, character: "\ue22f" },
  { code: 214, character: "\ue276" },
  { code: 170, character: "\ue22a" },
  { code: 189, character: "\ue23d" },
  { code: 186, character: "\ue23b" },
  { code: 156, character: "\ue25c" },
  { code: 128, character: "\ue240" },
  { code: 255, character: "\ue2bf" },
  { code: 232, character: "\ue2a8" },
  { code: 220, character: "\ue27c" },
  { code: 230, character: "\ue2e6" },
  { code: 209, character: "\ue271" },
  { code: 215, character: "\ue277" },
  { code: 193, character: "\ue261" },
  { code: 211, character: "\ue273" },
  { code: 216, character: "\ue278" },
  { code: 218, character: "\ue27a" },
  { code: 222, character: "\ue27e" },
  { code: 224, character: "\ue220" },
];

const colourCodes = [
  { code: 0, colour: "black" },
  { code: 1, colour: "white" },
  { code: 2, colour: "redDark" },
  { code: 3, colour: "cyan" },
  { code: 4, colour: "magenta" },
  { code: 5, colour: "greenDark" },
  { code: 6, colour: "blueDark" },
  { code: 7, colour: "yellow" },
  { code: 8, colour: "brown" },
  { code: 9, colour: "brownDark" },
  { code: 10, colour: "red" },
  { code: 11, colour: "grayDark" },
  { code: 12, colour: "gray" },
  { code: 13, colour: "green" },
  { code: 14, colour: "blue" },
  { code: 15, colour: "grayLight" },
];

const Petscii = ({art, art2, caption, simulator, animated, auto, reversed}) => {
  const [chars, setChars] = useState([]);
  const [palette, setPalette] = useState(variants[0].palette);
  const [colNum, setColNum] = useState(0);
  const [animDirection, setAnimDirection] = useState(0);
  const [animRunning, setAnimRunning] = useState(auto);
  
  useEffect(() => {
    const variant = variants.find(e => e.name === art);
    var width =  variant.data.framebufs[0].width;
    var characters = variant.data.framebufs[0].screencodes;
    var colours = variant.data.framebufs[0].colors;
    if (art2) {
      const variant2 = variants.find(e => e.name === art2);
      width *= 2;
      for (var ch of variant2.data.framebufs[0].screencodes) characters.push(parseInt(ch));
      for (var ch of variant2.data.framebufs[0].colors) colours.push(parseInt(ch));
    }
    
    var bgCol = colourCodes.find((el) => el.code == variant.data.framebufs[0].backgroundColor).colour;
    setPalette(variant.palette);

    var temp1 = [];
    var temp2 = [];
    for (let i = 0; i < characters.length; i++) {
      var j = i;
      if (art2) {
        j = Math.floor(i / (width / 2)) % 2 == 0 ?
          i - (width / 2) * (Math.floor(i / (width))) :
          i - (width / 2) * (Math.floor(i / (width))) + (characters.length / 2) - (width / 2);
      }
        
      var ch = {
        character: petsciiCodes.find((el) => el.code == characters[j]).character,
        colour: colourCodes.find((el) => el.code == colours[j]).colour,
        bgColour: bgCol,
      };

      temp1.push(ch);
      if ((i + 1) % width == 0) {
        temp2.push(temp1);
        temp1 = [];
      }
    }
    setChars(temp2);
  }, []);

  const cols =  [
    // [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
    [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
    [17, 18, 19, 21, 22, 23, 24, 25, 26, 27, 28, 29, 31, 32, 33],
    [17, 18, 19, 21, 23, 24, 25, 26, 27, 29, 31, 32, 33],
    [17, 18, 20, 23, 25, 27, 29, 32, 33],
    [17, 25, 33],
    [17],
    [0],
    [0, 8, 16],
    [0, 1, 4, 6, 8, 10, 12, 15, 16],
    [0, 1, 2, 4, 6, 7, 8, 9, 10, 12, 14, 15, 16],
    [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  ] ;
  
  const animRef = useRef();

  const handleAnimation = async () => {
    setAnimRunning(true);
    const delay = 100;

    if (!animDirection) {
      for (var i = 0; i < cols.length - 1; i++) {
        setColNum(n => n + 1);
        await new Promise(r => setTimeout(r, delay));
      }
      setAnimDirection(1);
    }
    else {
      for (var i = 0; i < cols.length - 1; i++) {
        setColNum(n => n - 1);
        await new Promise(r => setTimeout(r, delay));
      }
      setAnimDirection(0);
    }
    setAnimRunning(false);
  }

  useEffect(() => {
    if (auto) handleAnimation();
  }, []);

  var classNames = "center";
  if (reversed) classNames += " reversed";

  return (
    <Fragment>
      <pre className={classNames} onClick={!animRunning ? handleAnimation : null}>
        {chars.map((r, i) => {
          var row = r.map((ch, j) =>
            !animated || cols[colNum].find(e => e === j) > -1 ?
                <pre key={j} className={`c64 petsciiChar fg-${palette}-${ch.colour} bg-${palette}-${ch.bgColour}`}>
                  {ch.character}
                </pre> : null
          )
          return ( <pre key={i}>{row}</pre>)
          }
        )}
      </pre>
      {caption && !(animated && colNum < cols.length/2) ? <div className={!simulator ? "width80" : "width34"}><Spacer />{caption}</div> : <Spacer size={8} />}
    </Fragment>
  );
}


export default Petscii;
