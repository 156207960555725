import Spacer from "../components/spacer";
import Ascii from "../components/ascii";
import AnimationBtd from "../components/animationBtd";
import { Fragment } from "react";
import Petscii from "../components/petscii";
import MyLink from "../components/myLink";
import Flower from "../components/flower";

const Home = () => {
  return (
    <Fragment>
      <div className="center">littlebitspace</div>
      <Spacer />
      <div className="width80">
        The story of littlebitspace (lbs) began in late 2019 with small ASCII doodles on Nano CLI. The themes have been widely varying from massive cathedrals to satanic statues, from zodiac signs to esoteric symbols, not to mention all the human and animal skulls drawn along the dire journey. Incidentally delving into the realms of PETSCII, the Tarot project was initiated - a card deck that could not be left unfinished. Nowadays, there's a <MyLink title="shop" url="https://etsy.com/shop/littlebitspaceShop/" /> open for those who love dark aesthetics and textmode art, and this site was set up but to gather all relevant links together along with some of my best work in a texty format.
      </div>
      <Spacer />
      <div className="width80">
        I was adopted by <MyLink title="Mistigris" url="https://mistigris.org/" /> early on. With the computer arts group, my art pieces have been regularly featured in their monthly artpack releases at <MyLink title="16colors" url="https://16colo.rs/group/mistigris" />. Back in 2021, I initiated the infamous artpack MIST0421, <MyLink title="Divine Providence" url="https://16colo.rs/pack/mist0421/" />, which got its controversial reputation quickly. A year later I was determined to undertake a large-scale solo project and thus the Tarot project commenced. The Tarot cards were posted on a daily pace during this drawing marathon and the entire deck got wrapped together and released in an artpack <MyLink title="lbs-tarot" url="https://16colo.rs/pack/lbs-tarot/" />.
      </div>
      <Spacer />
      <div className="width80">
        My relatively unique style is recognised within the textmode community because I haven't been good at following the industry standards and traditions about font, character dimensions, texty file formats and overall style. From day one, I have been using Ubuntu Mono due to its modern and elegant look and feel. To boot, the font of my choosing isn't a hardware font which should be preferred according to some. Additionally, the themes in my work have been leaning increasingly towards the uncommon: gothic aesthetics, esotericism and all that. In conclusion, my art is conscious choices of the tools and methods used, and the rules broken.
      </div>
      <Spacer size={2} />

      <div className="center">Portfolio</div>
      <Spacer />
      <div className="width80">        
        The pieces I have gathered on this section are my personal favourites. Trying to select pieces in which different techniques were used, this page unintentionally resulted filling up with lots and lots of skulls and skeletons, along with a few classical art pieces. Maybe that is fate, maybe just pure coincidence but I like the aesthetics. The full littlebitspace catalogue, dating back to late 2019, can be found from my <MyLink title="Instagram feed" url="https://instagram.com/littlebitspace" />.
      </div>
      <Spacer size={2} />

      <Flower id="withering-flowers" caption={<div className="width80"><MyLink title="Withering Flowers" url="https://www.instagram.com/p/C0Z8S9CvuEk/" />, 2023 | Interactive PETSCII</div>} />
      <Spacer />
      <div className="width80">
The flower you are seeing right now is unique. It'll only bloom for this day and for you. After a day your flower will perish into the digital void forever. This piece explores the ephemerality of human experience. Embrace the moment while it lasts.
      </div>
      <Spacer size={2} />

      <Petscii art="the last gambit" caption={<div className="width80"><MyLink title="The Last Gambit" url="https://www.instagram.com/p/Ctrd1JhOJ1R/" />, 2023 | PETSCII</div>} />
      <Spacer />
      <div className="width80">
There is a time when a reign ends and new period begins; the king has fallen and something new will emerge from the dust. Death sprouts life. I've been trying my best developing as an artist for a few years now. This represents the transition from merely practising drawing to imbuing every piece with a deeper meaning.
      </div>
      <Spacer size={2} />

      <Ascii art="eternity_reaching_out" p2a caption={<div className="width80"><MyLink title="Eternity Reaching Out" url="https://www.instagram.com/p/CthaWoAOE__/" />, 2023 | ANSIfied Custom PETSCII</div>} />
      <Spacer />
      <div className="width80">
Creation of Adam is purely fictional. My interpretation shows the reality of the proclaimed situation. We can spend eternities reaching out to the nonexistent or we can move forward and appreciate new ways of existing both as humankind and as an art scene. The display method for this piece was chosen for the very purposes and as it benefits from a big display.
      </div>
      <Spacer size={2} />

      <Petscii art="composition" caption={<div className="width80"><MyLink title="Composition" url="https://www.instagram.com/p/Cip5P6KNJA7/" />, 2022 | Monochromatic PETSCII</div>} />
      <Spacer />
      <div className="width80">
This PETSCII is an adaptation of the tiny ASCII piece <MyLink title="Small Skull" url="https://www.instagram.com/p/CiKM2LbjhP3/" />. However, just copying the previous work of art wasn't pleasing enough and thus the skulls were stacked on top of each other resulting in a satisfying triangle shape. Realising I haven't explored 1-bit colours properly, I might delve deeper into this style. 
      </div>
      <Spacer size={2} />

      <Petscii art="garden" caption={<div className="width80"><MyLink title="The Garden of Death" url="https://www.instagram.com/p/Cg_oCe4ORz3/" />, 2022 | Oversized PETSCII</div>} />
      <Spacer />
      <div className="width80">
        Traditional C64 PETSCII canvas scales up to 40x25 characters. However for a huge piece like this one it wasn't enough. Strongly mimicking <MyLink title="Hugo Simberg" url="https://en.wikipedia.org/wiki/Hugo_Simberg" />'s original watercolour painting from 1896, this was digitally assembled on a canvas scaling up to 55x41 to best match the proportions of the original. On top of that, I had to construct a script to convert PETSCII, through JSON and Unicode, into an HTML presentation to preserve the 100% textiness rate of this page.
      </div>
      <Spacer size={2} />

      <Petscii art="t0001" caption={<div className="width80"><MyLink title="The Magician" url="https://www.instagram.com/p/CdGFl4Hue6h/" />, 2022 | PETSCII Tarot card</div>} />
      <Spacer />
      <div className="width80">
        It's the most iconic card from the Tarot project. The Magician is the first card in the deck and it's usually depicted on the cover of <MyLink title="Rider-Waite" url="https://en.wikipedia.org/wiki/Rider%E2%80%93Waite_tarot_deck" />-inspired Tarot decks. The rest of the cards in this series can be discovered at my social media feeds, the <MyLink title="Tarot Simulator" url="https://littlebitspace.com/tarot" openInSame />, and you can even find this card design on a <MyLink title="t-shirt" url="https://www.etsy.com/listing/1256769706/t-shirt-magician" />. The production of the much demanded physical decks is being figured out and it's only a matter of time and money to make it happen at this point.
      </div>
      <Spacer size={2} />

      <AnimationBtd caption={<div className="width80"><MyLink title="Bite The Dust" url="https://www.instagram.com/p/CZ4Ulm1q1Ju/" />, 2022 | Old school ASCII animation</div>} />
      <Spacer />
      <div className="width80">
        Animating ASCII isn't a simple feat; each frame has to be drawn by hand. Whereas small scale animations with one or two moving characters are common, large-scale animated ASCII with jitter is something from another world. Helping to randomise the symbols in each hand-drawn frame, going through the character palette one by one, I developed a script to assign alternative symbols. It was tedious work and I haven't used the script since. Oh, but it was worth it!
      </div>
      <Spacer size={2}/>

      <Ascii art="untitled" caption={<div className="width80"><MyLink title="[untitled]" url="https://www.instagram.com/p/CVyHqDsLQmz/" />, 2021 | Old school ASCII</div>} />
      <Spacer />
      <div className="width80">
        This style, old school ASCII, has a long history. I have been experimenting with it in different situations. Varying the degree of detailing, I think I've landed on the sweetspot where I feel the most confident. Usually, seeing the picture in old school ASCII graphics requires the audience to use imagination especially in smaller scales. When done right it is a powerful yet simple style which somewhat represents the pencil drawing of the textmode universe.
      </div>
      <Spacer size={2} />

      <Ascii art="xray" caption={<div className="width80"><MyLink title="X-Ray" url="https://www.instagram.com/p/CVCyC3yrVLY/" />, 2021 | Mixed ASCII</div>} />
      <Spacer />
      <div className="width80">
        True mastery comes from a combination of perfected techniques, right? This piece in all its simplicity is a blend of new school, ASCII dotwork, anti-aliasing and shading. Distinctly, the superimposed effect makes the 3-dimensional cranium pop out of the greyed canvas. All in all, it's just a handful of illusions.
      </div>
      <Spacer size={2} />

      <Ascii art="venus" caption={<div className="width80"><MyLink title="Venus" url="https://www.instagram.com/p/CTK_PYXrvs-/" />, 2021 | New school ASCII</div>} />
      <Spacer />
      <div className="width80">
        In the textmode cosmos, new school ASCII is pretty common nowadays. The style is often associated with graffiti-like logos with ever so smooth curves. But that's not my kind of soup. I utilise this patterning technique to conjure up the third dimension. In addition, I have found that filling the shapes with text makes the piece immensely more interesting.
      </div>
      <Spacer size={2} />

      <Ascii art="dead" caption={<div className="width80"><MyLink title="Dead?" url="https://www.instagram.com/p/CQpINJdC4RC/" />, 2021 | ASCII dotwork</div>} />
      <Spacer />
      <div className="width80">
        Ah, good old ASCII dotwork - a style I did not see anyone practising so I had to invent it myself. It clearly strikes a resemblance with pixel art due to playing with the density of the characters. This style works since we're prone to seeing patterns. I have created <MyLink title="an educational video" url="https://youtu.be/U1qZ5D1HeXA" /> about the underlying psychology if you are interested in why we see pictures in a bunch of rows of text. Because in fact, that's what ASCII practically is. Nothing less, nothing more.
      </div>

    </Fragment>
  );
}

export default Home;
