import { Fragment } from "react";

const Textfield = ({name, type="text", height=1, value, onChange, disabled}) => {
  var rows = [];
  for (var i = 0; i < height; i++) {
    rows.push(i);
  }
  return (
    <pre>
      {`.${"-".repeat(78)}.`}<br />
      <pre className="left">{rows.map((row) => <Fragment key={row} >| <br /></Fragment>)}</pre>
      {height <= 1 ?
        <input
          className="input left"
          name={name}
          type={type}
          maxLength={76}
          onChange={onChange}
          value={value}
          disabled={disabled}
        /> :
        <textarea
          className="input left"
          name={name}
          rows={height}
          onChange={onChange}
          value={value}
          disabled={disabled}
        />
      }
      <pre className="left">{rows.map((row) => <Fragment key={row} > |<br /></Fragment>)}</pre>
      {rows.map((row) => <br key={row} />)}
      {`\`${"-".repeat(78)}'`}<br />
    </pre>
  );
};

export default Textfield;
