// CONF

const pages1 = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Commissions",
    url: "/commissions",
  },
  {
    name: "Tarot",
    url: "/tarot",
  },
  {
    name: "Polaroid",
    url: "/polaroid",
  },
  {
    name: "Resources",
    url: "/resources",
  },
  // {
  //   name: "NFT",
  //   url: "/nft",
  // },
];

const pages2 = [
  {
    name: "Shop",
    url: "https://etsy.com/shop/littlebitspaceShop/",
    external: true,
  },
  {
    name: "Support me",
    url: "https://paypal.me/littlebitspace",
    external: true,
  },
];

const socials = [
  {
    name: "Instagram",
    url: "https://instagram.com/littlebitspace/",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/littlebitspace/",
  },
  {
    name: "Mastodon",
    rel: "me",
    url: "https://mas.to/@littlebitspace",
  },
  {
    name: "Discord",
    url: "https://discord.com/users/986565166206443621",
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/channel/UChurZineKdjj4fyDHAg3R_Q/",
  },
  {
    name: "Email",
    url: "mailto:me@littlebitspace.com",
  },
];

export { pages1, pages2, socials };
