import { Fragment } from "react";

import Spacer from "./spacer";
import Ascii from "./ascii";
import { NavLink } from "react-router-dom";
import MyLink from "./myLink";
import Greeter from "./greeter";

const Navi = ({pages1, pages2, socials, onChangeNav}) => {
  return (
    <div className="width80">
      <Spacer />
      <Ascii art="logo" />
      {/* <Greeter /> */}
      {/* <Spacer /> */}
      <Spacer />
      <nav className="center">
        {pages1.map((page, index) => 
          <Fragment key={index}>
            {!page.external ?
              <NavLink
                className="clickable"
                to={page.url}
                onClick={() => onChangeNav(page.url)}
              >
                &nbsp;{page.name}&nbsp;
              </NavLink> : 
              <MyLink
                title={`${page.name} ^`}
                padding
                url={page.url}
              />
            }
            {index !== pages1.length - 1 && <span className="center"> | </span>}
          </Fragment>
        )}
        <Spacer />
        {pages2.map((page, index) => 
          <Fragment key={index}>
            {!page.external ?
              <NavLink
                className="clickable"
                to={page.url}
                onClick={() => onChangeNav(page.url)}
              >
                &nbsp;{page.name}&nbsp;
              </NavLink> : 
              <MyLink
                title={`${page.name} ^`}
                padding
                url={page.url}
              />
            }
            {index !== pages2.length - 1 && <span className="center"> | </span>}
          </Fragment>
        )}
      </nav>
      <Spacer />
      <div className="center">
        {socials.map((social, index) =>
          <Fragment key={index}>
            <MyLink
              strikethrough={social.strikethrough}
              title={social.name}
              padding
              rel={social.rel}
              url={social.url}
            />
            {index !== socials.length - 1 && <span className="center"> | </span>}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Navi;
