import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import Spacer from "./components/spacer";
import Footer from "./components/footer";
import Navi from "./components/navi";
import Settings from "./components/settings";

import { pages1, pages2, socials } from "./data/data";
import Foreground from "./components/foreground";

const App = () => {
  const [fontSize, setFontSize] = useState();
  const [effectsOn, setEffectsOn] = useState(true);
  const [nav, setNav] = useState("");
  
  const handleScrollToTop = () => window.scrollTo({top: 0, behavior: 'smooth'});

  const handleChangeFontSize = (direction) => {
    var el = document.getElementById('root');
    var style = window.getComputedStyle(el, null).getPropertyValue('font-size');
    var curSize = parseFloat(style);
    var newSize = direction == "inc" ? (curSize + 8) : curSize - 8;

    var styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;
    styleSheet.insertRule(`* { font-size: ${newSize}px }`);

    setFontSize(newSize);
  }

  const handleToggleEffects = () => {
    setEffectsOn(!effectsOn);
    if (effectsOn) {
      document.querySelectorAll('[class*="clickable"]')
        .forEach(el => el.style.textShadow = "none");
      document.querySelectorAll('[class*="width80"],[class*="center"],[class*="left"],[class*="right"],[class*="frame"]')
        .forEach(el => el.style.textShadow = "none");

    }
    else {
      document.querySelectorAll('[class*="clickable"]')
        .forEach(el => el.style.textShadow = 
          "2px 0 4px #ff000029, -2px 0 4px #ff000029, 5px 0 7px #ff000029, -5px 0 7px #ff000029, 8px 0 12px #ff000029, -8px 0 12px #ff000029");
      document.querySelectorAll('[class*="width80"],[class*="center"],[class*="left"],[class*="right"],[class*="frame"]')
        .forEach(el => el.style.textShadow = "2px 0 4px #ffffff29, -2px 0 4px #ffffff29, 5px 0 7px #ffffff29, -5px 0 7px #ffffff29, 8px 0 12px #ffffff29, -8px 0 12px #ffffff29");
    }
  }

  useEffect(() => {
    var el = document.getElementById('root');
    var style = window.getComputedStyle(el, null).getPropertyValue('font-size');
    var curSize = parseFloat(style);

    var styleEl = document.createElement("style");
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;
    styleSheet.insertRule(`* { font-size: ${curSize}px }`);

    setFontSize(curSize);
  }, [nav]);

  return(
    <div>
      <Spacer size={1} />
      <Settings
        onChangeFontSize={handleChangeFontSize}
        onToggleEffects={handleToggleEffects}
        effectsOn={effectsOn}
        fontSize={fontSize}
      />
      <Spacer size={2} />
      <Navi
        pages1={pages1}
        pages2={pages2}
        socials={socials}
        onChangeNav={setNav}
      />
      <Spacer size={2} />
      <Outlet />
      <Spacer size={2} />
      <Footer onClick={handleScrollToTop} />
      <Spacer />
      <Foreground effectsOn={effectsOn} />
    </div>
  );
}

export default App;
