import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import Home from "./routes/home";
import Commissions from "./routes/commissions";
import Polaroid from "./routes/polaroid";
import Nft from "./routes/nft/nft";
import E404 from "./routes/e404";
import TarotSimulator from "./routes/tarotSimulator";
import reportWebVitals from "./reportWebVitals";
import store from "./routes/nft/redux/store";
import { Provider } from "react-redux";
import './styles.css';
import Resources from "./routes/resources";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Home />} />
        {/* <Route path="nft" element={
          <Provider store={store}>
            <Nft />
          </Provider>
        } /> */}
        <Route path="commissions" element={<Commissions />} />
        {/* <Route path="gallery" Commissionselement={<Gallery />}>
          <Route index element={ <p>Select art</p> } />
          <Route path=":artId" element={<Art />} />
        </Route> */}
        <Route path="tarot" element={<TarotSimulator />} />
        <Route path="polaroid" element={<Polaroid />} />
        <Route path="resources" element={<Resources /> } />
        <Route path="*" element={<E404 />} />
      </Route>
    </Routes>
    
  </BrowserRouter>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
