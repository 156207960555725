import { Fragment } from "react";

const MyLink = ({title="Link", url, padding, openInSame, strikethrough, rel}) => {
  return (
    <a
      className={`clickable ${strikethrough && "strikethrough"}`}
      href={url}
      rel={rel}
      target={!openInSame ? "_blank" : ""}
    >
      {padding && <Fragment>&nbsp;</Fragment>}
      {title}
      {padding && <Fragment>&nbsp;</Fragment>}
    </a>
  );
};

export default MyLink;
