import { useEffect, useState } from "react";
import Loader from "./loader";

const { Configuration, OpenAIApi } = require("openai");

// const OPENAI_API_KEY = "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";
const OPENAI_API_KEY = "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";
const ORGANIZATION_ID = "org-D0NcLJtkscGYHU1mza9gRsam";


const Greeter = () => {
  const [greet, setGreet] = useState();

  useEffect(async () => {
    const configuration = new Configuration({
      apiKey: process.env.OPENAI_API_KEY,
      // apiKey: OPENAI_API_KEY,
      organization: process.env.ORGANIZATION_ID,
    });
    const openai = new OpenAIApi(configuration);

    const response = await openai.createCompletion({
      model: "gpt-3.5-turbo",
      prompt: "Welcome users on a webpage containing ASCII and PETSCII art consisting of mostly darker stuff such as skulls, skeletons, but classical themes as well. The site advocates for textmode arts with a modern, eccentric, somewhat unusual and unique take. The style is often described as contemporary. My artist handle is \"littlebitspace\". The phrase should NOT exceed 78 characters in length including white spaces. ONLY USE STANDARD ASCII CHARACTERS!",
      temperature: 0.7,
      max_tokens: 256,
      // top_p: 1,
      // frequency_penalty: 0,
      // presence_penalty: 0,
    });
    if (!response) getGreet();
    console.log(response.data.choices[0].text)
    setGreet(response.data.choices[0].text)
  }, []);

  return (
    <div>
      {!greet ?
        <div className="center">
          <Loader length={5} delay={250} spaceWidth={1} />
        </div>
        : <div className="width80">
            "{greet.trim().replaceAll('"', "")}"
            <div className="right">
              -- OpenAI
            </div>
          </div>
      }
      
    </div>
  );
}

export default Greeter;