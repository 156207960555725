import { Fragment } from "react";

import Ascii from "../components/ascii";
import ContactForm from "../components/contactForm";
import Spacer from "../components/spacer";
import MyLink from "../components/myLink";
import Petscii from "../components/petscii";

const Commissions = () => {
  return (
    <Fragment>
        <div className="center">Commissions</div>
        <Spacer />
        <div className="width80">
          Commissions are very much welcome! It's the interaction between art and audience that keeps me motivated and it is an additional pleasure to work with people and provide them with art that amazes them. If you have the slightest idea please do not hesitate to contact me!
        </div>
        <Spacer size={2} />
        <div className="center">
          Instructions&nbsp;
        </div>
        <Spacer />
        <div className="width80">
          Feel free to reach out to me in any way you feel the most natural - remember I'm only a human, like you, after all. If you are still unsure where to start or how to initiate the conversation that's fine. Here's some pointers for you:
        </div>
        <Spacer />
        <ol className="width80">
          <li>
            Contact me wherever (socials at the top or contact form below)
          </li>
          <li>
            Describe what you want me to draw
          </li>
          <li>
            Include details (character width and/or height, preferred font, style, etc.)
          </li>
          <li>
            Add reference pictures if any - a picture is worth a thousand words.
          </li>
          <li>Approximate your budget. If you have no idea we can work that out together!</li>
        </ol>
      <Spacer />
      <div className="width80">
        I charge 50% of the approximate price in advance as a security.
      </div>
      <Spacer size={2} />
      <div className="center">Contact form</div>
      <Spacer />
      <ContactForm />
      <Spacer size={2} />
      <div className="center">Artist's picks</div>
      <Spacer />
      <div className="width80">Still considering whether to commission me to do art or not? I've gathered below my personal favourites of all the commissioned art work I have made. Each one of them is curated to the client's needs regarding the style, dimensions and budget among other aspects. Expect master quality!</div>
      <Spacer size={2} />
      <Ascii art="x86re" caption={<div>A logo design for <MyLink title="x86re" url="https://x86re.com" /></div>} />
      <Spacer size={4} />
      <Ascii art="20forbeer" caption={<div>BBS welcoming screen for 20ForBeers </div>} />
      <Spacer size={4} />
      <Petscii art="realhackhistory" caption={<div>A Twitter profile picture design for <MyLink title="realhackhistory" url="https://twitter.com/realhackhistory" /></div>} />
    </Fragment>
  );
}

export default Commissions;
