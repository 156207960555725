import { useEffect, useState } from "react";

const DEVMODE = false;

const LIMITS = {
  BLUR: {
    MIN: 0,
    MAX: 0.6,
  },
  SATURATION: {
    MIN: 84,
    MAX: 116,
  },
  HUE: {
    MIN: -10,
    MAX: 10,
  }
};

const Foreground = ({effectsOn}) => {
  const [blur, setBlur] = useState(LIMITS.BLUR.MAX / 2);  // mid
  const [saturation, setSaturation] = useState(100);      // mid
  const [hue, setHue] = useState(0);                      // mid

  useEffect(async () => {
    const delayFunction = ms => new Promise(res => setTimeout(res, ms));
    await delayFunction(30);
    if (!effectsOn) return;
    
    const rand1 = Math.floor(Math.random() * 2);
    const rand2 = Math.floor(Math.random() * 2);
    const rand3 = Math.floor(Math.random() * 2);

    const blurInc = !DEVMODE ? 0.01 : 0.1
    const saturationInc = !DEVMODE ? 0.1 : 1
    const hueInc = !DEVMODE ? 0.05 : 0.5

    setBlur(blur > LIMITS.BLUR.MIN ? rand1 == 0 ? blur - blurInc : blur < LIMITS.BLUR.MAX ? blur + blurInc : blur - blurInc : blur + blurInc)
    setSaturation(saturation > LIMITS.SATURATION.MIN ? rand2 == 0 ? saturation - saturationInc : saturation < LIMITS.SATURATION.MAX ? saturation + saturationInc : saturation - saturationInc : saturation + saturationInc)
    setHue(hue > LIMITS.HUE.MIN ? rand3 == 0 ? hue - hueInc : hue < LIMITS.HUE.MAX ? hue + hueInc : hue - hueInc : hue + hueInc)
  }, [blur])

  const foregroundStyle = {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    pointerEvents: "none",
    backdropFilter: `blur(${blur}px) saturate(${saturation}%) hue-rotate(${hue}deg)`,
  }

  return (
    <div style={foregroundStyle} />
  )
}

export default Foreground;