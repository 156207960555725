import { Fragment, useEffect, useRef, useState } from 'react';

import emailjs from '@emailjs/browser';

import Clickable from "./clickable";
import Spacer from "./spacer";
import Textfield from "./textfield";

import { USER_ID, SERVICE_ID, TEMPLATE_ID } from "../keys/emailkey"

const ContactForm = () => {
  const form = useRef();

  const [messageSent, setMessageSent] = useState(false);
  const [messageSentError, setMessageSentError] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState("");
  const [isRobot, setIsRobot] = useState(true);
  
  const [nameError, setNameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [subjectError, setSubjectError] = useState(true);
  const [messageError, setMessageError] = useState(true);
  const [budgetError, setBudgetError] = useState(true);

  function isEmailValid(email) {
    const emailRegexp = new RegExp(
      /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i
    )
    return emailRegexp.test(email)
  }

  useEffect(() => {
    setNameError(name.length < 1);
    setEmailError(!isEmailValid(email));
    setSubjectError(subject.length < 8);
    setMessageError(message.length < 50);
    setBudgetError(budget.length < 2);
  }, [name, email, subject, message, budget, isRobot]);
  
  const handleSubmit = (e) => {
    if (!nameError && !emailError && !subjectError && !messageError && !budgetError && !isRobot) {
      e.preventDefault();
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
        .then((result) => {
          setMessageSent(true);
          setMessageSentError(false);
        }, (error) => {
          setMessageSentError(true);
        });
    }
  };

  const handleClear = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setBudget("");
    setIsRobot(true);
    setMessageSent(false);
  }

  const requiredChar = "*";

  return (
    <div className="width80">
      <form ref={form}>
        Name
        {nameError && <div className='right'>{requiredChar}</div>}
        <Textfield
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={messageSent}
        />
        <Spacer />
        Email
        {emailError && <div className='right'>{requiredChar}</div>}
        <Textfield
          name="email" 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={messageSent}
        />
        <Spacer />
        Subject
        {subjectError && <div className='right'>{requiredChar}</div>}
        <Textfield
          name="subject" 
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          disabled={messageSent}
        />
        <Spacer />
        Message
        {messageError && <div className='right'>{requiredChar}</div>}
        <Textfield
          name="message"
          height={12}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          disabled={messageSent}
        />
        <Spacer />
        Budget
        {budgetError && <div className='right'>{requiredChar}</div>}
        <Textfield
          name="budget"
          value={budget}
          onChange={(e) => setBudget(e.target.value)}
          disabled={messageSent}
        />
      </form>
      <Spacer />
      {!messageSent ? 
        <Fragment>
          <Clickable title={isRobot ? "[ ]" : "[X]"} onClick={() => setIsRobot(!isRobot)} />
          &nbsp;Not a robot 
          <Spacer />
          <Clickable
            title='Send'
            onClick={handleSubmit}
            disabled={nameError || emailError || subjectError || messageError || budgetError || isRobot}
          />
          {messageSentError && <div className='right'>Something went wrong. Try again.</div>}
        </Fragment> :
        <Fragment>
          <Spacer size={2} />
          Message sent! I'll get back to you soon.
          <div className='right'>
            <Clickable title='Clear' onClick={handleClear} />
          </div>
        </Fragment>
      }
    </div>
  );
};

export default ContactForm;
