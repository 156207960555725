const tarotDeck = [
  {
    name: "The Fool",
    imgName: "t0000",
    upright: ["Innocence", "New Beginnings", "Free spirit", ""],
    reversed: ["Recklessness", "Taken advantage of", "Inconsideration", ""],
  },
  {
    name: "The Magician",
    imgName: "t0001",
    upright: ["Willpower", "Desire", "Creation", "Manifestation"],
    reversed: ["Trickery", "Illusions", "Out of touch", ""],
  },
  {
    name: "The High Priestess",
    imgName: "t0002",
    upright: ["Intuitive", "Unconscious", "Inner voice", ""],
    reversed: ["Lack of center", "Lost inner voice", "Repressed feelings", ""],
  },
  {
    name: "The Empress",
    imgName: "t0003",
    upright: ["Motherhood", "Fertility", "Nature", ""],
    reversed: ["Dependence", "Smothering", "Emptiness", "Nosiness"],
  },
  {
    name: "The Emperor",
    imgName: "t0004",
    upright: ["Authority", "Structure", "Control", "Fatherhood"],
    reversed: ["Tyranny", "Rigidity", "Coldness", ""],
  },
  {
    name: "The Hierophant",
    imgName: "t0005",
    upright: ["Tradition", "Conformity", "Morality", "Ethics"],
    reversed: ["Rebellion", "Subversiveness", "New approaches", ""],
  },
  {
    name: "The Lovers",
    imgName: "t0006",
    upright: ["Partnership", "Duality", "Union", ""],
    reversed: ["Loss of Balance", "One-sidedness", "Disharmony", ""],
  },
  {
    name: "The Chariot",
    imgName: "t0007",
    upright: ["Direction", "Control", "Willpower", ""],
    reversed: ["Lack of control", "Lack of direction", "Aggression", ""],
  },
  {
    name: "Strength",
    imgName: "t0008",
    upright: ["Inner strength", "Bravery", "Compassion", "Focus"],
    reversed: ["Self doubt", "Weakness", "Insecurity", ""],
  },
  {
    name: "The Hermit",
    imgName: "t0009",
    upright: ["Contemplation", "Search for truth", "Inner guidance", ""],
    reversed: ["Loneliness", "isolation", "Lost your way", ""],
  },
  {
    name: "Wheel of Fortune",
    imgName: "t0010",
    upright: ["Change", "Cycles", "Inevitable fate", ""],
    reversed: ["No control", "Clinging to control", "Bad luck", ""],
  },
  {
    name: "Justice",
    imgName: "t0011",
    upright: ["Cause and effect", "Clarity", "Truth", ""],
    reversed: ["Dishonesty", "Unaccountability", "Unfairness", ""],
  },
  {
    name: "The Hanged Man",
    imgName: "t0012",
    upright: ["Sacrifice", "Release", "Martyrdom", ""],
    reversed: ["Stalling", "Needless sacrifice", "Fear of sacrifice", ""],
  },
  {
    name: "Death",
    imgName: "t0013",
    upright: ["End of cycle", "Beginnings", "Change", "Metamorphosis"],
    reversed: ["Fear of change", "Holding on", "Stagnation", "Decay"],
  },
  {
    name: "Temperance",
    imgName: "t0014",
    upright: ["Middle path", "Patience", "Finding meaning", ""],
    reversed: ["Extremes", "Excess", "Lack of balance", ""],
  },
  {
    name: "The Devil",
    imgName: "t0015",
    upright: ["Addiction", "Materialism", "Playfulness", ""],
    reversed: ["Freedom", "Release", "Restoring control", ""],
  },
  {
    name: "The Tower",
    imgName: "t0016",
    upright: ["Sudden upheaval", "Broken Pride", "Disaster", ""],
    reversed: ["Disaster avoided", "Delayed disaster", "Fear of suffering", ""],
  },
  {
    name: "The Star",
    imgName: "t0017",
    upright: ["Hope", "Faith", "Rejuvenation", ""],
    reversed: ["Faithlessness", "Discouragement", "Insecurity", ""],
  },
  {
    name: "The Moon",
    imgName: "t0018",
    upright: ["Unconsciousness", "Illusions", "Intuition", ""],
    reversed: ["Confusion", "Fear", "Misinterpretation", ""],
  },
  {
    name: "The Sun",
    imgName: "t0019",
    upright: ["Joy", "Success", "Celebration", "Positivity"],
    reversed: ["Negativity", "Depression", "Sadness", ""],
  },
  {
    name: "Judgement",
    imgName: "t0020",
    upright: ["Reflection", "Reckoning", "Awakening", ""],
    reversed: ["Lack of self awareness", "Doubt", "Self loathing", ""],
  },
  {
    name: "The World",
    imgName: "t0021",
    upright: ["Fulfillment", "Harmony", "Completion", ""],
    reversed: ["Incompletion", "No closure", "", ""],
  },
  {
    name: "Ace of Wands",
    imgName: "t0101",
    upright: ["Creation", "Willpower", "Inspiration", "Desire"],
    reversed: ["Lack of energy", "Lack of passion", "Boredom", ""],
  },
  {
    name: "Two of Wands",
    imgName: "t0102",
    upright: ["Planning", "Making decisions", "Leaving home", ""],
    reversed: ["Fear of change", "Playing safe", "Bad planning", ""],
  },
  {
    name: "Three of Wands",
    imgName: "t0103",
    upright: ["Looking ahead", "Expansion", "Rapid growth", ""],
    reversed: ["Obstacles", "Delays", "Frustration", ""],
  },
  {
    name: "Four of Wands",
    imgName: "t0104",
    upright: ["Community", "Home", "Celebration", ""],
    reversed: ["Lack of support", "Transience", "Home conflicts", ""],
  },
  {
    name: "Five of Wands",
    imgName: "t0105",
    upright: ["Competition", "Rivalry", "Conflict", ""],
    reversed: ["Avoiding conflict", "Respecting differences", "", ""],
  },
  {
    name: "Six of Wands",
    imgName: "t0106",
    upright: ["Victory", "Success", "Public reward", ""],
    reversed: ["Excess pride", "Lack of recognition", "Punishment", ""],
  },
  {
    name: "Seven of Wands",
    imgName: "t0107",
    upright: ["Perseverance", "Defensive", "Maintaining control", ""],
    reversed: ["Give up", "Destroyed confidence", "Overwhelmed", ""],
  },
  {
    name: "Eight of Wands",
    imgName: "t0108",
    upright: ["Rapid action", "Movement", "Quick decisions", ""],
    reversed: ["Panic", "Waiting", "Slowdown", ""],
  },
  {
    name: "Nine of Wands",
    imgName: "t0109",
    upright: ["Resilience", "Grit", "Last stand", ""],
    reversed: ["Exhaustion", "Fatigue", "Questioning motivation", ""],
  },
  {
    name: "Ten of Wands",
    imgName: "t0110",
    upright: ["Accomplishment", "Responsibility", "Burden", ""],
    reversed: ["Inability to delegate", "Overstressed", "Burnt out", ""],
  },
  {
    name: "Page of Wands",
    imgName: "t0111",
    upright: ["Exploration", "Excitement", "Freedom", ""],
    reversed: ["Lack of direction", "Procrastination", "Creating conflict", ""],
  },
  {
    name: "Knight of Wands",
    imgName: "t0112",
    upright: ["Action", "Adventure", "Fearlessness", ""],
    reversed: ["Anger", "Impulsiveness", "Recklessness", ""],
  },
  {
    name: "Queen of Wands",
    imgName: "t0113",
    upright: ["Courage", "Determination", "Joy", ""],
    reversed: ["Selfishness", "Jealousy", "Insecurity", ""],
  },
  {
    name: "King of Wands",
    imgName: "t0114",
    upright: ["Big picture", "Leader", "Overcoming challenges", ""],
    reversed: ["Impulsive", "Overbearing", "Unreal expectations", ""],
  },
  {
    name: "Ace of Cups",
    imgName: "t0201",
    upright: ["New feelings", "Spirituality", "Intuition", ""],
    reversed: ["Emotional loss", "Blocked creativity", "Emptiness", ""],
  },
  {
    name: "Two of Cups",
    imgName: "t0202",
    upright: ["Unity", "Partnership", "Connection", ""],
    reversed: ["Imbalance", "Broken communication", "Tension", ""],
  },
  {
    name: "Three of Cups",
    imgName: "t0203",
    upright: ["Friendship", "Community", "Happiness", ""],
    reversed: ["Overindulgence", "Gossip", "Isolation", ""],
  },
  {
    name: "Four of Cups",
    imgName: "t0204",
    upright: ["Apathy", "Contemplation", "Disconnectedness", ""],
    reversed: ["Sudden awareness", "Choosing happiness", "Acceptance", ""],
  },
  {
    name: "Five of Cups",
    imgName: "t0205",
    upright: ["Loss", "Grief", "Self-pity", ""],
    reversed: ["Acceptance", "Moving on", "Finding peace", ""],
  },
  {
    name: "Six of Cups",
    imgName: "t0206",
    upright: ["Familiarity", "Happy memories", "Healing", ""],
    reversed: ["Moving forward", "Leaving home", "Independence", ""],
  },
  {
    name: "Seven of Cups",
    imgName: "t0207",
    upright: ["Searching for purpose", "Choices", "Daydreaming", ""],
    reversed: ["Lack of purpose", "Diversion", "Confusion", ""],
  },
  {
    name: "Eight of Cups",
    imgName: "t0208",
    upright: ["Walking away", "Disillusionment", "Leaving behind", ""],
    reversed: ["Avoidance", "Fear of change", "Fear of loss", ""],
  },
  {
    name: "Nine of Cups",
    imgName: "t0209",
    upright: ["Satisfaction", "Emotional stability", "Luxury", ""],
    reversed: ["Lack of inner joy", "Smugness", "Dissatisfaction", ""],
  },
  {
    name: "Ten of Cups",
    imgName: "t0210",
    upright: ["Inner happiness", "Fulfillment", "Dreams coming true", ""],
    reversed: ["Shattered dreams", "Broken family", "Domestic disharmony", ""],
  },
  {
    name: "Page of Cups",
    imgName: "t0211",
    upright: ["Happy surprise", "Dreamer", "Sensitivity", ""],
    reversed: ["Emotional immaturity", "Insecurity", "Disappointment", ""],
  },
  {
    name: "Knight of Cups",
    imgName: "t0212",
    upright: ["Following the heart", "Idealist", "Romantic", ""],
    reversed: ["Moodiness", "Disappointment", "", ""],
  },
  {
    name: "Queen of Cups",
    imgName: "t0213",
    upright: ["Compassion", "Calm", "Comfort", ""],
    reversed: ["Martyrdom", "Insecurity", "Dependence", ""],
  },
  {
    name: "King of Cups",
    imgName: "t0214",
    upright: ["Compassion", "Control", "Balance", ""],
    reversed: ["Coldness", "Moodiness", "Bad advice", ""],
  },
  {
    name: "Ace of Swords",
    imgName: "t0301",
    upright: ["Breakthrough", "Clarity", "Sharp mind", ""],
    reversed: ["Confusion", "Brutality", "Chaos", ""],
  },
  {
    name: "Two of Swords",
    imgName: "t0302",
    upright: ["Difficult choices", "Indecision", "Stalemate", ""],
    reversed: ["Lesser of two evils", "No right choice", "Confusion", ""],
  },
  {
    name: "Three of Swords",
    imgName: "t0303",
    upright: ["Heartbreak", "Suffering", "Grief", ""],
    reversed: ["Recovery", "Forgiveness", "Moving on", ""],
  },
  {
    name: "Four of Swords",
    imgName: "t0304",
    upright: ["Rest", "Restoration", "Contemplation", ""],
    reversed: ["Restlessness", "Burnout", "Stress", ""],
  },
  {
    name: "Five of Swords",
    imgName: "t0305",
    upright: ["Unbridled ambition", "Win at all costs", "Sneakiness", ""],
    reversed: ["Lingering resentment", "Desire to reconcile", "Forgiveness", ""],
  },
  {
    name: "Six of Swords",
    imgName: "t0306",
    upright: ["Transition", "Leaving behind", "Moving on", ""],
    reversed: ["Emotional baggage", "Unresolved issues", "Resisting transition", ""],
  },
  {
    name: "Seven of Swords",
    imgName: "t0307",
    upright: ["Deception", "Trickery", "Tactics and strategy", ""],
    reversed: ["Coming clean", "Rethinking approach", "Deception", ""],
  },
  {
    name: "Eight of Swords",
    imgName: "t0308",
    upright: ["Imprisonment", "Entrapment", "Self-victimisation", ""],
    reversed: ["Self-acceptance", "New perspective", "Freedom", ""],
  },
  {
    name: "Nine of Swords",
    imgName: "t0309",
    upright: ["Anxiety", "Hopelessness", "Trauma", ""],
    reversed: ["Hope", "Reaching out", "Despair", ""],
  },
  {
    name: "Ten of Swords",
    imgName: "t0310",
    upright: ["Failure", "Collapse", "Defeat", ""],
    reversed: ["Can't get worse", "Only upwards", "Inevitable end", ""],
  },
  {
    name: "Page of Swords",
    imgName: "t0311",
    upright: ["Curiosity", "Restlessness", "Mental energy", ""],
    reversed: ["Deception", "Manipulation", "All talk", ""],
  },
  {
    name: "Knight of Swords",
    imgName: "t0312",
    upright: ["Action", "Impulsiveness", "Defending beliefs", ""],
    reversed: ["No direction", "Disregard consequences", "Unpredictability", ""],
  },
  {
    name: "Queen of Swords",
    imgName: "t0313",
    upright: ["Complexity", "Perceptiveness", "Clear mindedness", ""],
    reversed: ["Cold hearted", "Cruel", "Bitterness", ""],
  },
  {
    name: "King of Swords",
    imgName: "t0314",
    upright: ["Head over heart", "Discipline", "Truth", ""],
    reversed: ["Manipulative", "Cruel", "Weakness", ""],
  },
  {
    name: "Ace of Coins",
    imgName: "t0401",
    upright: ["Opportunity", "Prosperity", "New venture", ""],
    reversed: ["Lost opportunity", "Missed chance", "Bad investment", ""],
  },
  {
    name: "Two of Coins",
    imgName: "t0402",
    upright: ["Balancing decisions", "Priorities", "Adapting to change", ""],
    reversed: ["Loss of balance", "Disorganised", "Overwhelmed", ""],
  },
  {
    name: "Three of Coins",
    imgName: "t0403",
    upright: ["Teamwork", "Collaboration", "Building", ""],
    reversed: ["Lack of teamwork", "Disorganised", "Group conflict", ""],
  },
  {
    name: "Four of Coins",
    imgName: "t0404",
    upright: ["Conservation", "Frugality", "Security", ""],
    reversed: ["Greediness", "Stinginess", "Possessiveness", ""],
  },
  {
    name: "Five of Coins",
    imgName: "t0405",
    upright: ["Need", "Poverty", "Insecurity", ""],
    reversed: ["Recovery", "Charity", "Improvement", ""],
  },
  {
    name: "Six of Coins",
    imgName: "t0406",
    upright: ["Charity", "Generosity", "Sharing", ""],
    reversed: ["Strings attached", "Stinginess", "Power and domination", ""],
  },
  {
    name: "Seven of Coins",
    imgName: "t0407",
    upright: ["Hard work", "Perseverance", "Diligence", ""],
    reversed: ["Work without results", "Distractions", "Lack of rewards", ""],
  },
  {
    name: "Eight of Coins",
    imgName: "t0408",
    upright: ["Apprenticeship", "Passion", "High standards", ""],
    reversed: ["Lack of passion", "Uninspired", "No motivation", ""],
  },
  {
    name: "Nine of Coins",
    imgName: "t0409",
    upright: ["Fruits of labour", "Rewards", "Luxury", ""],
    reversed: ["Reckless spending", "Living beyond means", "False success", ""],
  },
  {
    name: "Ten of Coins",
    imgName: "t0410",
    upright: ["Legacy", "Culmination", "Inheritance", ""],
    reversed: ["Fleeting success", "Lack of stability", "Lack of resources", ""],
  },
  {
    name: "Page of Coins",
    imgName: "t0411",
    upright: ["Ambition", "Desire", "Diligence", ""],
    reversed: ["Lack of commitment", "Greediness", "Laziness", ""],
  },
  {
    name: "Knight of Coins",
    imgName: "t0412",
    upright: ["Efficiency", "Hard work", "Responsibility", ""],
    reversed: ["Laziness", "Obsessiveness", "Work without reward", ""],
  },
  {
    name: "Queen of Coins",
    imgName: "t0413",
    upright: ["Practicality", "Creature comforts", "Financial security", ""],
    reversed: ["Self-centredness", "Jealousy", "Smothering", ""],
  },
  {
    name: "King of Coins",
    imgName: "t0414",
    upright: ["Abundance", "Prosperity", "Security", ""],
    reversed: ["Greed", "Indulgence", "Sensuality", ""],
  },
];

const divModes = [
  {
    name: "past",
    slots: ["Past", "Present", "Future"],
  },
  {
    name: "relationship",
    slots: ["You", "Relationship", "Partner"],
  },
  {
    name: "situation",
    slots: ["Situation", "Action", "Outcome"],
  },
  {
    name: "idea",
    slots: ["Idea", "Process", "Aspiration"],
  },
  {
    name: "connection",
    slots: ["Mind", "Body", "Spirit"],
  },
];

const suits = [
  {
    name: "Major Arcana",
    startIndex: 0,
    endIndex: 21,
    cardNumbers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
  },
  {
    name: "Wands",
    startIndex: 22,
    endIndex: 35,
    cardNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  },
  {
    name: "Cups",
    startIndex: 36,
    endIndex: 49,
    cardNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  },
  {
    name: "Swords",
    startIndex: 50,
    endIndex: 63,
    cardNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  },
  {
    name: "Coins",
    startIndex: 64,
    endIndex: 77,
    cardNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  },
];

export { tarotDeck, divModes, suits };
