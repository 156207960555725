import { Fragment, useEffect, useState } from "react";

import Spacer from "./spacer";

import btd0 from "../animation/btd/0.txt";
import btd1 from "../animation/btd/1.txt";
import btd2 from "../animation/btd/2.txt";
import btd3 from "../animation/btd/3.txt";
import btd4 from "../animation/btd/4.txt";
import btd5 from "../animation/btd/5.txt";
import btd6 from "../animation/btd/6.txt";
import btd7 from "../animation/btd/7.txt";
import btd8 from "../animation/btd/8.txt";
import btd9 from "../animation/btd/9.txt";
import btd10 from "../animation/btd/10.txt";
import btd11 from "../animation/btd/11.txt";
import btd12 from "../animation/btd/12.txt";
import btd13 from "../animation/btd/13.txt";
import btd14 from "../animation/btd/14.txt";
import btd15 from "../animation/btd/15.txt";

const AnimationBtd = ({caption}) => {
  const [frame0, setFrame0] = useState("");
  const [frame1, setFrame1] = useState("");
  const [frame2, setFrame2] = useState("");
  const [frame3, setFrame3] = useState("");
  const [frame4, setFrame4] = useState("");
  const [frame5, setFrame5] = useState("");
  const [frame6, setFrame6] = useState("");
  const [frame7, setFrame7] = useState("");
  const [frame8, setFrame8] = useState("");
  const [frame9, setFrame9] = useState("");
  const [frame10, setFrame10] = useState("");
  const [frame11, setFrame11] = useState("");
  const [frame12, setFrame12] = useState("");
  const [frame13, setFrame13] = useState("");
  const [frame14, setFrame14] = useState("");
  const [frame15, setFrame15] = useState("");

  const animate = (frames, fps = 1) => {
    var count = 1;
    setInterval(() => {
      for (var j = 0; j < frames.length; j++) {
        frames[j].style.display = (count % frames.length == j ? "block" : "none");
      }
      count++;
    }, 1000 / fps);
  }

  useEffect(() => {
    fetch(btd0).then(r => r.text()).then(text => setFrame0(text));
    fetch(btd1).then(r => r.text()).then(text => setFrame1(text));
    fetch(btd2).then(r => r.text()).then(text => setFrame2(text));
    fetch(btd3).then(r => r.text()).then(text => setFrame3(text));
    fetch(btd4).then(r => r.text()).then(text => setFrame4(text));
    fetch(btd5).then(r => r.text()).then(text => setFrame5(text));
    fetch(btd6).then(r => r.text()).then(text => setFrame6(text));
    fetch(btd7).then(r => r.text()).then(text => setFrame7(text));
    fetch(btd8).then(r => r.text()).then(text => setFrame8(text));
    fetch(btd9).then(r => r.text()).then(text => setFrame9(text));
    fetch(btd10).then(r => r.text()).then(text => setFrame10(text));
    fetch(btd11).then(r => r.text()).then(text => setFrame11(text));
    fetch(btd12).then(r => r.text()).then(text => setFrame12(text));
    fetch(btd13).then(r => r.text()).then(text => setFrame13(text));
    fetch(btd14).then(r => r.text()).then(text => setFrame14(text));
    fetch(btd15).then(r => r.text()).then(text => setFrame15(text));

    const frames = document.getElementsByClassName("frame");
    frames[0].style.display = "block";
    animate(frames, 3);
  }, []);

  return (
    <Fragment>
      <div className="center">
        <pre className="frame">{frame0}</pre>
        <pre className="frame">{frame1}</pre>
        <pre className="frame">{frame2}</pre>
        <pre className="frame">{frame3}</pre>
        <pre className="frame">{frame4}</pre>
        <pre className="frame">{frame5}</pre>
        <pre className="frame">{frame6}</pre>
        <pre className="frame">{frame7}</pre>
        <pre className="frame">{frame8}</pre>
        <pre className="frame">{frame9}</pre>
        <pre className="frame">{frame10}</pre>
        <pre className="frame">{frame11}</pre>
        <pre className="frame">{frame12}</pre>
        <pre className="frame">{frame13}</pre>
        <pre className="frame">{frame14}</pre>
        <pre className="frame">{frame15}</pre>
      </div>
      {caption && <div className="width80"><Spacer />{caption}</div>}
    </Fragment>
  );
}

export default AnimationBtd;
